import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

interface ContentComponentProps {
  someProp: string;
}

interface EditDialogProps {
  open: boolean;
  handleClose: (e: any) => void;
  billMade?: boolean;
  ContentComponent: React.ComponentType<ContentComponentProps>;
  defaultvalue?: any;
}

const EditDialog: React.FC<EditDialogProps> = ({
  open,
  handleClose,
  billMade,
  ContentComponent,
  defaultvalue,
}) => {
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...defaultvalue,
    },
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    if (defaultvalue) {
      reset(defaultvalue);
    }
  }, [defaultvalue, reset]);

  const onSubmit = (formData: any) => {
    handleClose({
      action: true,
      formData,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={(_: any, reason: any) => {
        if (reason !== 'backdropClick') {
          handleClose(false);
        }
      }}
      fullWidth
      maxWidth={billMade ? 'sm' : 'lg'}
      disableEscapeKeyDown
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ backgroundColor: '#f2f2f2' }}>
            {billMade ? (
              <p>The bill has been made and cannot be edited.</p>
            ) : (
              <ContentComponent someProp="example" />
            )}
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#f2f2f2' }}>
            {!billMade && (
              <>
                <Button
                  onClick={() => handleClose(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Update
                </Button>
              </>
            )}
            {billMade && (
              <Button
                onClick={() => handleClose(false)}
                variant="contained"
                color="primary"
              >
                OK
              </Button>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditDialog;
