import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import EditPdcHire from '@components/HireCharts/EditPdcHire';
import HireChartDetails from '@components/HireCharts/HireChartDetails';
import PageTitle from '@components/PageTitle';

import {
  useDeleteHireChart,
  useEditHireChart,
  useReadHireCharts,
} from '../../hooks/hirechart';
import { IHireChart } from '../../types/hirechart';

const BreakdownIncidence = () => {
  const { data: hirecharts, isLoading, refetch } = useReadHireCharts();

  const [localhirechart, setLocalHirechart] = useState<IHireChart[]>([]);
  const [hirechart, setHireChart] = useState<IHireChart | null>(null);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteHirechartMutate } = useDeleteHireChart();
  const { mutate: editHirechartMutate } = useEditHireChart();

  useEffect(() => {
    document.title = 'Reports - nunes';
    if (hirecharts) {
      const processedData = hirecharts
        .filter(item => item.break !== '0')
        .map(item => ({
          ...item,
          elo: getELO(item),
        }));
      setLocalHirechart(processedData);
    }
  }, [hirecharts]);

  const formatDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateStr;
    }
  };

  const getELO = (item: any) => {
    let elo = '';
    if (item.early === '1') elo = 'E';
    if (item.early_morning === '1') elo = 'EM';
    if (item.late === '1') elo += ' L';
    if (item.onite === '1') elo += ' O';
    return elo.trim();
  };

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setHireChart(rowData);
      setDrawerOpen(true);
    }
  };

  const handleEdit = (rowData: any) => {
    setHireChart(rowData);
    setDefaultvalue({
      date: rowData?.date,
      company: rowData?.company,
      ac: rowData?.ac,
      vtype: rowData?.vtype,
      ocode: rowData?.ocode,
      vno: rowData?.vno,
      driver: rowData?.driver,
      cleaner: rowData?.cleaner,
      hiretype: rowData?.hiretype,
      client: rowData?.client,
      subagent: rowData?.subagent,
      repveh: rowData?.repveh,
      rvocode: rowData?.rvocode,
      okm: rowData?.okm,
      ckm: rowData?.ckm,
      tkm: rowData?.tkm,
      akm: rowData?.akm,
      ttkm: rowData?.ttkm,
      bill: rowData?.bill,
      billno: rowData?.billno,
      toll: rowData?.toll,
      break: rowData?.break,
      remark: rowData?.remark,
      particulars: rowData?.particulars,
      particulars_type: rowData?.particulars_type,
      early: rowData?.early === '1',
      early_morning: rowData?.early_morning === '1',
      late: rowData?.late === '1',
      onite: rowData?.onite === '1',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setHireChart(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (hirechart && action && action?.action) {
      editHirechartMutate(
        { ...action?.formData, id: +hirechart.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (hirechart) {
      deleteHirechartMutate(
        { id: +hirechart.id },
        {
          onSuccess: () => {
            setLocalHirechart(prevHirechart =>
              prevHirechart.filter(c => c.id !== hirechart.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const columns: Column<IHireChart>[] = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => formatDate(rowData.date),
    },
    {
      title: 'Company',
      field: 'company',
    },
    {
      title: 'Veh Type',
      field: 'vtype',
    },
    {
      title: 'Own Code',
      field: 'ocode',
    },
    {
      title: 'Veh No.',
      field: 'vno',
    },
    {
      title: 'Rep Veh',
      field: 'repveh',
    },
    {
      title: 'Rep Own Code',
      field: 'rvocode',
    },
    {
      title: 'Particulars',
      field: 'particulars_type',
    },
    {
      title: 'Hire Type',
      field: 'hiretype',
    },
    {
      title: 'Sub Agent',
      field: 'subagent',
    },
    {
      title: 'Client',
      field: 'client',
    },
    {
      title: 'E/EM/L/O',
      field: 'elo',
    },
    {
      title: 'Toll',
      field: 'toll',
    },
    {
      title: 'Bill No.',
      field: 'billno',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Breakdown Incidence - Report" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localhirechart || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={HireChartDetails}
        data={hirechart}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditPdcHire}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default BreakdownIncidence;
