import { useFormContext } from 'react-hook-form';

import { Card, FormControl, Grid, TextField, Typography } from '@mui/material';

function EditContracts() {
  const { register } = useFormContext();

  // const [formData, setFormData] = useState({
  //   date: '',
  //   company: '',
  // });

  // const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleSelectChange = (e: SelectChangeEvent<string>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const { mutate } = useAddContracts();

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   mutate(
  //     {
  //       data: 'hello add contaract',
  //     },
  //     {
  //       onSuccess: data => {},
  //       onError: error => {},
  //     }
  //   );
  // };

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          {/* <form onSubmit={handleSubmit}> */}
          <Typography variant="h5">Edit Contract</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="date"
                label="Date"
                {...register('date')}
                type="date"
                variant="outlined"
                fullWidth
                // value={formData.date}
                // onChange={handleTextChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                {/* <InputLabel>Company</InputLabel>
                <Select
                  id="cname"
                  {...register('cname')}
                  // value={formData.company}
                  // onChange={handleSelectChange}
                  label="Company"
                  required
                >
                  <MenuItem value="">Select Company</MenuItem>
                  {companies.map(company => (
                    <MenuItem key={company} value={company}>
                      {company}
                    </MenuItem>
                  ))}
                </Select> */}
                <TextField
                  id="company"
                  label="Company"
                  {...register('company')}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* </form> */}
        </Card>
      </div>
    </div>
  );
}

export default EditContracts;
