import { useEffect, useState } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import { useStats } from '../../hooks/home';

const Dashboard = () => {
  const { mutate } = useStats();
  const theme = useTheme();

  const [stats, setStats] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    mutate(
      {},
      {
        onSuccess: data => {
          setStats(data?.data);
          setLoading(false);
        },
        onError: error => {
          console.error('Mutation failed:', error);
          setLoading(false);
        },
      }
    );
  }, [mutate]);

  if (loading) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          minHeight: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ flexGrow: 1, p: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={6}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                  }}
                >
                  <CalendarMonthIcon
                    style={{
                      width: '50px',
                      height: '50px',
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="h6">No. of Hire for the Day</Typography>
                  <Typography variant="h4" style={{ marginTop: '10px' }}>
                    {stats?.hiresPerDay}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                  }}
                >
                  <DirectionsBusIcon
                    style={{
                      width: '50px',
                      height: '50px',
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="h6">
                    Total number of Vehicles in use
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '10px' }}>
                    {stats?.totalNumberOfVehiclesInUse}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
              }}
            >
              <CardContent sx={{ width: '100%', height: '100%' }}>
                <Typography variant="h6" align="center" gutterBottom>
                  Income Over the Past Week
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={stats?.incomeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="income"
                      stroke={theme.palette.primary.main}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
