import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IReadSector, ISector } from '../../types/controlpanel/sector';

// READ ALL
const fetchSectors = async (): Promise<ISector[]> => {
  const { data } = await apiClient.get('/sector');
  return data;
};

export const useReadSectors = () =>
  useQuery<ISector[], Error>({
    queryKey: ['sectors', 'list'],
    queryFn: fetchSectors,
  });

// READ ONE
const fetchSector = async (params: IReadSector): Promise<ISector> => {
  const { data } = await apiClient.get(`/sector/${params.id}`);
  return data;
};

export const useReadSector = (params: IReadSector) =>
  useQuery<ISector, Error>({
    queryKey: ['sectors', 'details', params.id],
    queryFn: () => fetchSector(params),
  });

// CREATE
export const useAddSector = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-sector'],
    mutationFn: (data: any) => apiClient.post('/sector', data),
  });
  return {
    mutate,
  };
};

// UPDATE
export const useEditSector = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-sector'],
    mutationFn: (data: any) => apiClient.put(`/sector/${data?.id}`, data),
  });
  return { mutate };
};

// DELETE
export const useDeleteSector = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-sector'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/sector/${id}`),
  });
  return { mutate };
};
