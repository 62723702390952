import { useMutation } from '@tanstack/react-query';

import apiClient from '@constants/axios';

export const useStats = () => {
  const { mutate } = useMutation({
    mutationKey: ['list-stats'],
    mutationFn: (data: any) => apiClient.post(`/stats`, data),
  });
  return { mutate };
};
