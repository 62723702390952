import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IContact, IReadContact } from '../types/contact';

// READ ALL
const fetchContacts = async (): Promise<IContact[]> => {
  const { data } = await apiClient.get(`/contact`);
  return data;
};

export const useReadContacts = () =>
  useQuery<IContact[], Error>({
    queryKey: ['contacts', 'list'],
    queryFn: () => fetchContacts(),
  });

// READ ONE
const fetchContact = async (params: IReadContact): Promise<IContact> => {
  const { data } = await apiClient.get(`/contact/${params.id}`);
  return data;
};

export const useReadContact = (params: IReadContact) =>
  useQuery<IContact, Error>({
    queryKey: ['contacts', 'details', params.id],
    queryFn: () => fetchContact(params),
  });

// useMutation
export const useAddContact = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-contacts'],
    mutationFn: (data: any) => apiClient.post('/contact', data),
  });
  return {
    mutate,
  };
};

export const useEditContact = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-contacts'],
    mutationFn: (data: any) => apiClient.put(`/contact/${data?.id}`, data),
  });
  return {
    mutate,
  };
};

export const useDeleteContact = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-contacts'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/contact/${id}`),
  });
  return {
    mutate,
  };
};
