import {
  Box,
  Card,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { useReadDriver } from '../../../hooks/controlpanel/drivers';
import { IDrivers, IReadDrivers } from '../../../types/controlpanel/drivers';

interface DriverDetailProps {
  data?: IDrivers | null;
}

const DriverDetail = ({ data }: DriverDetailProps) => {
  const id = data?.id;
  const {
    data: driver,
    isLoading,
    isError,
  } = useReadDriver({ id } as IReadDrivers);

  if (isError) {
    return <div>Error fetching Contact</div>;
  }

  return (
    <div className="content">
      <div className="content-inner">
        <Box style={{ padding: '10px', margin: '10px' }}>
          <Typography variant="h5">Drivers Details</Typography>
        </Box>

        {isLoading ? (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Card style={{ margin: '10px' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Name:</strong>
                    </TableCell>
                    <TableCell>{driver?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Address:</strong>
                    </TableCell>
                    <TableCell>{driver?.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Phone (Residence):</strong>
                    </TableCell>
                    <TableCell>{driver?.rphone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Phone (Mobile):</strong>
                    </TableCell>
                    <TableCell>{driver?.mphone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Date of Joining:</strong>
                    </TableCell>
                    <TableCell>{driver?.jdate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Position:</strong>
                    </TableCell>
                    <TableCell>{driver?.role}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Remark:</strong>
                    </TableCell>
                    <TableCell>{driver?.remark}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
      </div>
    </div>
  );
};

export default DriverDetail;
