import React from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useReadTransfers } from '../../../../hooks/controlpanel/transfer';
import { useReadVehicleTypes } from '../../../../hooks/controlpanel/vehicletype';

const AddCompanyTariff = () => {
  const { register, control } = useFormContext();
  const { data: transfers } = useReadTransfers();
  const { data: vtypes } = useReadVehicleTypes();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  });

  const tid = useWatch({
    control,
    name: 'tid',
  });

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Add Company Tariff
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="tid-label">Transfer Type</InputLabel>
                <Select label="Transfer Type" {...register('tid')}>
                  {transfers?.map(transfer => (
                    <MenuItem key={transfer.id} value={transfer.id}>
                      {transfer?.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="ntid"
                variant="outlined"
                label="New Transfer Type"
                {...register('ntid')}
                fullWidth
                margin="normal"
              />
            </Grid>
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id={`data[${index}].vid-label`}>
                      Vehicle Type
                    </InputLabel>
                    <Select
                      labelId={`data[${index}].vid-label`}
                      id={`data[${index}].vid`}
                      label="Vehicle Type"
                      {...register(`data[${index}].vid`)}
                    >
                      {vtypes?.map(vtype => (
                        <MenuItem key={vtype.id} value={vtype.type}>
                          {vtype?.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id={`data[${index}].rate`}
                    variant="outlined"
                    label="Rate"
                    {...register(`data[${index}].rate`)}
                    fullWidth
                    type="number"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => remove(index)}
                    style={{ marginTop: '20px' }}
                  >
                    Remove
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => append({ vid: '', rate: '', tid })}
              >
                Add Rate
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default AddCompanyTariff;
