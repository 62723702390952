import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IReadStaff, IStaff } from '../../types/controlpanel/staff';

// READ ALL
const fetchStaffs = async (): Promise<IStaff[]> => {
  const { data } = await apiClient.get(`/staff`);
  return data;
};
export const useReadStaffs = () =>
  useQuery<IStaff[], Error>({
    queryKey: ['staff', 'list'],
    queryFn: fetchStaffs,
  });

// READ ONE
const fetchStaff = async (params: IReadStaff): Promise<IStaff> => {
  const { data } = await apiClient.get(`/staff/${params.id}`);
  return data;
};

export const useReadStaff = (params: IReadStaff) =>
  useQuery<IStaff, Error>({
    queryKey: ['staff', 'details', params.id],
    queryFn: () => fetchStaff(params),
  });

// useMutation
export const useAddStaff = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-staff'],
    mutationFn: (data: any) => apiClient.post('/staff', data),
  });
  return {
    mutate,
  };
};

export const useEditStaff = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-staff'],
    mutationFn: (data: any) => apiClient.put(`/staff/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteStaff = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-staff'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/staff/${id}`),
  });
  return { mutate };
};
