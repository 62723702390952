import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Card, Grid, TextField, Typography } from '@mui/material';

const EditContact: React.FC = () => {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ padding: '20px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit Contact
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cname"
                label="Company Name"
                {...register('cname')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="phone"
                label="Phone"
                {...register('phone')}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={6}>
              <TextField
                id="address"
                label="Address"
                {...register('address')}
                fullWidth
                multiline
                minRows={1}
                maxRows={1}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h6"
            style={{ marginBottom: '10px', marginTop: '20px' }}
          >
            Contact Person (Operation):
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpo_fname"
                label="First Name"
                {...register('cpo_fname')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpo_lname"
                label="Last Name"
                {...register('cpo_lname')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpo_phone1"
                label="Phone 1"
                {...register('cpo_phone1')}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpo_phone2"
                label="Phone 2"
                {...register('cpo_phone2')}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h6"
            style={{ marginBottom: '10px', marginTop: '20px' }}
          >
            Contact Person (Accounts):
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpa_fname"
                label="First Name"
                {...register('cpa_fname')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpa_lname"
                label="Last Name"
                {...register('cpa_lname')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpa_phone1"
                label="Phone 1"
                {...register('cpa_phone1')}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cpa_phone2"
                label="Phone 2"
                {...register('cpa_phone2')}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
      <br />
    </div>
  );
};

export default EditContact;
