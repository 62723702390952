export const formatDate = (dateStr: string | null) => {
  try {
    if (!dateStr) return '';

    const date = new Date(dateStr);

    const day = String(date.getDate()).padStart(2, '0');

    const month = String(date.getMonth() + 1).padStart(2, '0');

    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  } catch (error) {
    console.error('Error parsing date:', error);

    return '';
  }
};
