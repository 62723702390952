import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IExtra, IReadExtra } from '../../types/controlpanel/extras';

// READ ALL
const fetchExtras = async (): Promise<IExtra[]> => {
  const { data } = await apiClient.get(`/extra`);
  return data;
};

export const useReadExtras = () =>
  useQuery<IExtra[], Error>({
    queryKey: ['extra', 'list'],
    queryFn: fetchExtras,
  });

// READ ONE
const fetchExtra = async (params: IReadExtra): Promise<IExtra> => {
  const { data } = await apiClient.get(`/extra/${params.id}`);
  return data;
};

export const useReadExtra = (params: IReadExtra) =>
  useQuery<IExtra, Error>({
    queryKey: ['extra', 'details', params.id],
    queryFn: () => fetchExtra(params),
  });

// useMutation
export const useAddExtra = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-extra'],
    mutationFn: (data: any) => apiClient.post('/extra', data),
  });
  return {
    mutate,
  };
};

export const useEditExtra = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-extra'],
    mutationFn: (data: any) => apiClient.put(`/extra/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteExtra = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-extra'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/extra/${id}`),
  });
  return { mutate };
};
