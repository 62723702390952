import React, { useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  Box,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
  Typography,
} from '@mui/material';

import apiClient from '@constants/axios';
import { IMenuItem, menu } from '@constants/menu';

import { useSetDbYear } from '../../hooks/misc';

const Sidebar: React.FC<{ open: boolean }> = ({ open }) => {
  const navigate = useNavigate();
  const [dbYear, setDbYear] = useState(localStorage.getItem('year'));

  const { mutate: updateDbYearMutate } = useSetDbYear();

  const changeDbYear = (event: any) => {
    const year = String(event.target.value);
    setDbYear(year);
    localStorage.setItem('year', year);
    updateDbYearMutate(
      { year },
      {
        onSuccess: () => {
          window.location.reload();
        },
        onError: () => {
          enqueueSnackbar('Failed to Update year', { variant: 'error' });
        },
      }
    );
  };

  const startYear = 2020;
  const dbYearData = [];
  const currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();
  if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
    // eslint-disable-next-line no-plusplus
    currentYear--;
  }
  // eslint-disable-next-line no-plusplus
  for (let year = startYear; year <= currentYear + 1; year++) {
    dbYearData.push({ id: year, year });
  }

  const handleLogout = async () => {
    try {
      const response = await apiClient.get('/logout');
      if (response) {
        sessionStorage.removeItem('isAuthenticated');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <List sx={{ paddingRight: '1rem' }}>
          {menu.map((item: IMenuItem) => (
            <Box key={item?.title}>
              <MenuItem item={item} open={open} />
            </Box>
          ))}
        </List>
        <List sx={{ paddingRight: '1rem' }}>
          <ListItemButton style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
            <ListItemIcon style={{ minWidth: '40px' }}>
              <CalendarTodayIcon />
            </ListItemIcon>
            <FormControl variant="outlined" style={{ width: '160px' }}>
              <InputLabel htmlFor="outlined-native-simple">Year</InputLabel>
              <Select
                native
                value={dbYear}
                onChange={changeDbYear}
                label="Year"
                inputProps={{
                  name: 'year',
                  id: 'outlined-native-simple',
                }}
              >
                {dbYearData.map((t: any) => (
                  <option key={t.id} value={t.id}>
                    {t.year}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ListItemButton>

          <MenuItem
            key="Profile"
            item={{
              title: 'Profile',
              link: '/profile',
              icon: <ManageAccountsIcon />,
            }}
            open={open}
          />
          <ListItemButton
            onClick={handleLogout}
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
          >
            <ListItemIcon style={{ minWidth: '40px' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">Logout</Typography>}
              sx={{ marginLeft: '8px' }}
            />
          </ListItemButton>
        </List>
      </Grid>
    </Grid>
  );
};

const MenuItem = ({ item, open }: { item: IMenuItem; open: boolean }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} open={open} />;
};

const SingleLevel = ({ item }: { item: IMenuItem }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <ListItemButton
      key={item?.title}
      selected={item?.link === pathname}
      style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
    >
      <ListItemIcon style={{ minWidth: '40px' }}>{item?.icon}</ListItemIcon>
      {item?.link ? (
        <Link
          to={item?.link ?? ''}
          style={{ textDecoration: 'none', color: 'black', width: '100%' }}
        >
          <ListItemText
            primary={<Typography variant="body2">{item?.title}</Typography>}
            sx={{ marginLeft: '4px', padding: 0, margin: 0 }}
          />
        </Link>
      ) : (
        <ListItemText
          primary={<Typography variant="body2">{item?.title}</Typography>}
          sx={{ marginLeft: '4px', padding: 0, margin: 0 }}
        />
      )}
    </ListItemButton>
  );
};

const MultiLevel = ({
  item,
  open: isSidebarOpen,
}: {
  item: IMenuItem;
  open: boolean;
}) => {
  const { items: children } = item;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  return (
    <>
      <ListItemButton
        key={item?.title}
        onClick={handleClick}
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <ListItemIcon style={{ minWidth: '40px' }}>{item?.icon}</ListItemIcon>
        <ListItemText
          primary={<Typography variant="body2">{item?.title}</Typography>}
          sx={{ marginLeft: '4px' }}
        />
        <Box>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children?.map((child: IMenuItem) => (
            <Box
              key={child.title}
              sx={{
                paddingLeft: '1rem',
              }}
            >
              {isSidebarOpen ? (
                <MenuItem
                  key={child?.title}
                  item={child}
                  open={isSidebarOpen}
                />
              ) : null}
            </Box>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export function hasChildren(item: IMenuItem) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (!Array.isArray(children)) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export default Sidebar;
