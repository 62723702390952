import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  useAddDrivers,
  useReadDrivers,
} from '../../hooks/controlpanel/drivers';
import { useReadHotels } from '../../hooks/controlpanel/hotels';
import {
  useAddNCompany,
  useReadNCompanys,
} from '../../hooks/controlpanel/newcompanies';
import {
  useAddOwnerCode,
  useReadOwnerCodes,
} from '../../hooks/controlpanel/ownercode';
import {
  useAddVehicleNumber,
  useReadVehicleNumbers,
} from '../../hooks/controlpanel/vehiclenumber';
import {
  useAddVehicleType,
  useReadVehicleTypes,
} from '../../hooks/controlpanel/vehicletype';
import SelectionDropdown from './SelectionDropdown';

const EditNewHire = () => {
  const { control, register, setValue } = useFormContext();
  const { data: hotels, refetch: refetchHotels } = useReadHotels();

  const { data: companyList = [], refetch: refetchCompanies } =
    useReadNCompanys();
  const { data: vtypeList = [], refetch: refetchVTypes } =
    useReadVehicleTypes();
  const { data: vnumbers = [], refetch: refetchVNumbers } =
    useReadVehicleNumbers();
  const { data: ocodeList = [], refetch: refetchOCodes } = useReadOwnerCodes();
  const { data: drivers = [], refetch: refetchDrivers } = useReadDrivers();

  const { mutate: addNcompanyMutate } = useAddNCompany();
  const { mutate: addVehicletypeMutate } = useAddVehicleType();
  const { mutate: addVehiclenoMutate } = useAddVehicleNumber();
  const { mutate: addOcodeMutate } = useAddOwnerCode();
  const { mutate: addDriverMutate } = useAddDrivers();

  const [selectedValue, setSelectedValue] = useState<any>(null);
  const hireTypeList = ['ARR', 'A/S', 'D/A', 'DEP', 'DISP', 'S/D', 'S/S'];

  const openKM = useWatch({
    name: 'okm',
    control,
  });
  const closeKM = useWatch({
    name: 'ckm',
    control,
  });
  const totalKM = useWatch({
    name: 'tkm',
    control,
  });
  const additionalKM = useWatch({
    name: 'akm',
    control,
  });

  useEffect(() => {
    if (openKM && closeKM) {
      setValue('tkm', closeKM - +openKM);
    }
  }, [openKM, closeKM, setValue]);

  useEffect(() => {
    if (totalKM && additionalKM) {
      setValue('ttkm', +totalKM + +additionalKM);
    }
  }, [totalKM, additionalKM, setValue]);

  useEffect(() => {
    if (selectedValue && !selectedValue.id) {
      const company = {
        name: selectedValue.name || '',
        info: '',
      };
      addNcompanyMutate(company, {
        onSuccess: () => {
          refetchCompanies();
        },
        onError: () => {
          enqueueSnackbar('Failed to Edit content', { variant: 'error' });
        },
      });

      const vtype = {
        type: selectedValue.type || '',
        info: '',
      };
      addVehicletypeMutate(vtype, {
        onSuccess: () => {
          refetchVTypes();
        },
        onError: () => {
          enqueueSnackbar('Failed to Edit content', {
            variant: 'error',
          });
        },
      });

      const vno = {
        num: selectedValue.number || '',
        info: '',
      };
      addVehiclenoMutate(vno, {
        onSuccess: () => {
          refetchVNumbers();
        },
        onError: () => {
          enqueueSnackbar('Failed to Edit content', { variant: 'error' });
        },
      });

      const ocode = {
        code: selectedValue.code || '',
        info: '',
        mobile: '',
        phone: '',
      };
      addOcodeMutate(ocode, {
        onSuccess: () => {
          refetchOCodes();
        },
        onError: () => {
          enqueueSnackbar('Failed to Edit content', { variant: 'error' });
        },
      });
    }
  }, [
    selectedValue,
    addOcodeMutate,
    refetchOCodes,
    refetchCompanies,
    refetchVTypes,
    addNcompanyMutate,
    addVehicletypeMutate,
    addVehiclenoMutate,
    refetchVNumbers,
    addDriverMutate,
    refetchDrivers,
    refetchHotels,
    drivers,
  ]);

  const driverList = drivers.filter((driver: any) => driver.role === 'Driver');
  const cleanerList = drivers.filter(
    (driver: any) => driver.role === 'Cleaner'
  );

  const sortedDrivers = [...driverList].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const sortedCleaners = [...cleanerList].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const sortedHotels = Array.isArray(hotels) ? hotels : [];

  sortedHotels.sort((a, b) => a.hotel_initial.localeCompare(b.hotel_initial));

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ padding: '10px' }}>
          <Typography variant="h4">Edit Hire</Typography>
          <Grid
            container
            spacing={2}
            style={{ paddingBottom: '10px', marginTop: '10px' }}
          >
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectionDropdown
                name="company"
                options={companyList}
                label="Company"
                renderLabel={(name: any) => name?.name}
                displayLabel="name"
                setSelectedValue={(data: any) => setSelectedValue(data)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectionDropdown
                name="vtype"
                options={vtypeList}
                label="Vehicle Type"
                renderLabel={(type: any) => type?.type}
                displayLabel="type"
                setSelectedValue={(data: any) => setSelectedValue(data)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectionDropdown
                name="vno"
                options={vnumbers}
                label="Vehicle no."
                renderLabel={(num: any) => num?.number}
                displayLabel="number"
                setSelectedValue={(data: any) => setSelectedValue(data)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectionDropdown
                name="ocode"
                options={ocodeList}
                label="Own Code"
                renderLabel={(ocode: any) => ocode?.code}
                displayLabel="code"
                setSelectedValue={(data: any) => setSelectedValue(data)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="driver"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => field.onChange(value?.name || '')}
                    value={
                      sortedDrivers.find(
                        option => option.name === field.value
                      ) || null
                    }
                    disablePortal
                    options={sortedDrivers}
                    getOptionLabel={option => option?.name ?? ''}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderInput={params => (
                      <TextField {...params} label="Drivers" />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="cleaner"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => field.onChange(value?.name || '')}
                    value={
                      sortedCleaners.find(
                        option => option.name === field.value
                      ) || null
                    }
                    disablePortal
                    options={sortedCleaners}
                    getOptionLabel={option => option?.name ?? ''}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderInput={params => (
                      <TextField {...params} label="Cleaners" />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Hire Type</InputLabel>
                <Controller
                  name="hiretype"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Hire Type">
                      <MenuItem value="">Select</MenuItem>
                      {hireTypeList.map((hire, index) => (
                        <MenuItem key={index} value={hire}>
                          {hire}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="client"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Client Name" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="subagent"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Sub Agent" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="repveh"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="repveh"
                    label="Rep Veh."
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="rvocode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="rvocode"
                    label="Rep Code"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="okm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    id="okm"
                    label="Open KM"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="ckm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    id="ckm"
                    label="Close KM"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="tkm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="tkm"
                    type="number"
                    label="Total KM"
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: !!field.value,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="akm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="akm"
                    type="number"
                    label="Additional Total"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="ttkm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="ttkm"
                    type="number"
                    label="G. Total"
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: !!field.value,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="billno"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="billno"
                    type="number"
                    label="Bill No."
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="toll"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="toll"
                    type="number"
                    label="Toll"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Bill</InputLabel>
                <Controller
                  name="bill"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Bill">
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="0">Unbilled</MenuItem>
                      <MenuItem value="1">Billed</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Breakdown</InputLabel>
                <Controller
                  name="break"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Breakdown">
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="0">No-Breakdown</MenuItem>
                      <MenuItem value="1">Breakdown</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="particulars"
                control={control}
                render={({ field }) => {
                  const hotelInitials =
                    hotels?.map(hotel => hotel.hotel_initial) ?? [];
                  const currentValues = field.value
                    ? field.value.split('/')
                    : [];

                  const handleChange = (_: any, newValue: any[]) => {
                    const newValueString = newValue.join('/');
                    field.onChange(newValueString);
                  };

                  return (
                    <Autocomplete
                      multiple
                      disablePortal
                      options={hotelInitials}
                      value={currentValues}
                      onChange={handleChange}
                      renderInput={params => (
                        <TextField {...params} label="Transfer" />
                      )}
                      renderTags={(tags, getTagProps) =>
                        tags.map((tag, index) => (
                          <Chip
                            label={tag}
                            {...getTagProps({ index })}
                            key={index}
                          />
                        ))
                      }
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="particularsType"
                label="Particulars Type"
                {...register('particulars_type')}
                fullWidth
                multiline
                rows={1}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="remark"
                label="Remark"
                {...register('remark')}
                fullWidth
                multiline
                rows={1}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="early"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                    }
                    id="early"
                    label="Early"
                  />
                )}
              />
              <Controller
                name="early_morning"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                    }
                    id="early_morning"
                    label="E. Morning"
                  />
                )}
              />
              <Controller
                name="late"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                    }
                    id="late"
                    label="Late"
                  />
                )}
              />

              <Controller
                name="onite"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={e => field.onChange(e.target.checked)}
                      />
                    }
                    id="onite"
                    label="O.Nite"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default EditNewHire;
