import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IHireChart, IReadHireChart } from '../types/hirechart';

// READ ALL
const fetchHireCharts = async (): Promise<IHireChart[]> => {
  const { data } = await apiClient.get(`/hirechart`);
  return data;
};

export const useReadHireCharts = () =>
  useQuery<IHireChart[], Error>({
    queryKey: ['hirechart', 'list'],
    queryFn: () => fetchHireCharts(),
  });

// READ ONE
const fetchHireChart = async (params: IReadHireChart): Promise<IHireChart> => {
  const { data } = await apiClient.get(`/hirechart/${params.id}`);
  return data;
};

const fetchHireChartMulti = async (params: IReadHireChart) => {
  const { data } = await apiClient.get(`/hirechart/${params.id}`);

  const { data: billData } = await apiClient.post('/bill/hid', {
    hid: data.id,
  });
  return {
    hirechart: data,
    bill: billData,
  };
};

export const useReadHireChart = (params: IReadHireChart) =>
  useQuery<IHireChart, Error>({
    queryKey: ['hirechart', 'details', params.id],
    queryFn: () => fetchHireChart(params),
  });

const fetchRates = async () => {
  const { data } = await apiClient.get(`/rates/all`);
  return data;
};

export const useReadRates = () =>
  useQuery<any, Error>({
    queryKey: ['rates', 'list'],
    queryFn: () => fetchRates(),
  });

export const useReadHireChartMulti = (params: IReadHireChart) =>
  useQuery<any, Error>({
    queryKey: ['hirechart', 'detailsMulti', params.id],
    queryFn: () => fetchHireChartMulti(params),
  });

// useMutation
export const useAddHireChart = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-hirechart'],
    mutationFn: (data: any) => apiClient.post('/hirechart', data),
  });
  return {
    mutate,
  };
};

export const useEditHireChart = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-hirechart'],
    mutationFn: (data: any) => apiClient.put(`/hirechart/${data?.id}`, data),
  });
  return {
    mutate,
  };
};

export const useUpdateHireChart = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-hirechart'],
    mutationFn: (data: any) => apiClient.put(`/hirechart/${data?.id}`, data),
  });
  return {
    mutate,
  };
};

export const useFilterHireChart = () => {
  const { mutate, data, isPending } = useMutation({
    mutationKey: ['filter-hirechart'],
    mutationFn: (payload: any) =>
      apiClient.post(`/hirechart/filtered`, payload),
  });
  return {
    mutate,
    data: data?.data?.hirecharts || [],
    isPending,
  };
};

export const useDeleteHireChart = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-hirechart'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/hirechart/${id}`),
  });
  return {
    mutate,
  };
};

const getHirechartsFiltered = async (req: any) => {
  const params: any = {};

  if (req.startDate) params.startDate = req.startDate;
  if (req.endDate) params.endDate = req.endDate;
  if (req.company && req.company !== 'any') params.company = req.company;
  const { data } = await apiClient.post(`/hirechart/filtered`, params);
  return data;
};

export const useGetHirechartsFiltered = (params: any, search: boolean) =>
  useQuery<any, Error>({
    queryKey: ['search', search],
    queryFn: () => getHirechartsFiltered(params),
  });
