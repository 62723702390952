import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import EditPdcHire from '@components/HireCharts/EditPdcHire';
import HireChartDetails from '@components/HireCharts/HireChartDetails';
import PageTitle from '@components/PageTitle';

import {
  useDeleteHireChart,
  useEditHireChart,
  useFilterHireChart,
} from '../../hooks/hirechart';
import { IHireChart } from '../../types/hirechart';

const VehicleSuppliers = () => {
  const [localhirechart, setLocalHirechart] = useState<IHireChart[]>([]);
  const [hirechart, setHireChart] = useState<IHireChart | null>(null);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteHirechartMutate } = useDeleteHireChart();
  const { mutate: editHirechartMutate } = useEditHireChart();
  const { mutate: filterHireChartMutate, isPending: isLoading } =
    useFilterHireChart();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: (() => {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          2
        );
        return firstDayOfMonth.toISOString().split('T')[0];
      })(),
      endDate: (() => {
        const today = new Date();
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        );
        return lastDayOfMonth.toISOString().split('T')[0];
      })(),
      owncode: '',
    },
  });

  const formatDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateStr;
    }
  };

  const getELO = (item: any) => {
    let elo = '';
    if (item.early === '1') elo = 'E';
    if (item.early_morning === '1') elo = 'EM';
    if (item.late === '1') elo += ' L';
    if (item.onite === '1') elo += ' O';
    return elo.trim();
  };

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setHireChart(rowData);
      setDrawerOpen(true);
    }
  };
  const handleEdit = (rowData: any) => {
    setHireChart(rowData);
    setDefaultvalue({
      date: rowData?.date,
      company: rowData?.company,
      ac: rowData?.ac,
      vtype: rowData?.vtype,
      ocode: rowData?.ocode,
      vno: rowData?.vno,
      driver: rowData?.driver,
      cleaner: rowData?.cleaner,
      hiretype: rowData?.hiretype,
      client: rowData?.client,
      subagent: rowData?.subagent,
      repveh: rowData?.repveh,
      rvocode: rowData?.rvocode,
      okm: rowData?.okm,
      ckm: rowData?.ckm,
      tkm: rowData?.tkm,
      akm: rowData?.akm,
      ttkm: rowData?.ttkm,
      bill: rowData?.bill,
      billno: rowData?.billno,
      toll: rowData?.toll,
      break: rowData?.break,
      remark: rowData?.remark,
      particulars: rowData?.particulars,
      particulars_type: rowData?.particulars_type,
      early: rowData?.early === '1',
      early_morning: rowData?.early_morning === '1',
      late: rowData?.late === '1',
      onite: rowData?.onite === '1',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setHireChart(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (hirechart && action && action?.action) {
      editHirechartMutate(
        { ...action?.formData, id: +hirechart.id },
        {
          onSuccess: () => {
            handleSearch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (hirechart) {
      deleteHirechartMutate(
        { id: +hirechart.id },
        {
          onSuccess: () => {
            setLocalHirechart(prevHirechart =>
              prevHirechart.filter(c => c.id !== hirechart.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleSearch = handleSubmit((formData: any) => {
    const filterCriteria = {
      ...(formData?.startDate && { startDate: formData?.startDate }),
      ...(formData?.endDate && { endDate: formData?.endDate }),
      ...(formData?.owncode && { owncode: formData?.owncode }),
      vehicleSupplierPage: true,
    };

    filterHireChartMutate(filterCriteria, {
      onSuccess: (filteredData: any) => {
        if (filteredData?.data) {
          const processedData = filteredData?.data?.map((item: any) => ({
            ...item,
            elo: getELO(item),
          }));
          setLocalHirechart(processedData);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch filtered data', { variant: 'error' });
      },
    });
  });

  useEffect(() => {
    document.title = 'Reports - nunes';
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Column<IHireChart>[] = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => formatDate(rowData.date),
    },
    {
      title: 'Company',
      field: 'company',
    },
    {
      title: 'Veh Type',
      field: 'vtype',
    },
    {
      title: 'Own Code',
      field: 'ocode',
    },
    {
      title: 'Veh No.',
      field: 'vno',
    },
    {
      title: 'Rep Veh',
      field: 'repveh',
    },
    {
      title: 'Rep Own Code',
      field: 'rvocode',
    },
    {
      title: 'Particulars',
      field: 'particulars_type',
    },
    {
      title: 'Hire Type',
      field: 'hiretype',
    },
    {
      title: 'Sub Agent',
      field: 'subagent',
    },
    {
      title: 'Client',
      field: 'client',
    },
    {
      title: 'E/EM/L/O',
      field: 'elo',
    },
    {
      title: 'Toll',
      field: 'toll',
    },
    {
      title: 'Bill No.',
      field: 'billno',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Vehicle Suppliers - Report" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Accordion style={{ padding: '10px' }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: 'primary.main',
                    '&.Mui-expanded': { transform: 'rotate(180deg)' },
                  }}
                />
              }
              aria-controls="filter-content"
              id="filter-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            >
              <Typography variant="h6">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="From Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="To Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Controller
                      control={control}
                      name="owncode"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Vehicle Supplier"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localhirechart || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: false,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
                Toolbar: () => <div style={{ margin: '10px' }}> </div>,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={HireChartDetails}
        data={hirechart}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditPdcHire}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default VehicleSuppliers;
