import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, Grid, Typography } from '@mui/material';

import PageTitle from '@components/PageTitle';

const buttonsData = [
  { label: 'Vehicle Type', path: '/vehicleType' },
  { label: 'Vehicle Nos.', path: '/vehicleNumber' },
  { label: 'Coach Supplier Codes', path: '/ownerCode' },
  { label: 'Drivers/Cleaners', path: '/driver' },
  { label: 'Office Staff', path: '/staff' },
  { label: 'Transfer Types', path: '/transfer' },
  { label: 'Companies', path: '/companies' },
  { label: 'Sub Agents', path: '/subAgent' },
  { label: 'General Tariff', path: '/generalTariff' },
  { label: 'Users', path: '/users' },
  { label: 'Extra Charges', path: '/extra' },
  { label: 'GST', path: '/gst' },
  { label: 'Hotels', path: '/hotel' },
  { label: 'Sector', path: '/sector' },
  { label: 'New Companies', path: '/newCompanies' },
  { label: 'Bill Dates', path: '/billDates' },
];

function ControlPanel() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Control Panel - nunes';
  }, []);
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Grid
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Control Panel" />
      </Grid>
      <Card style={{ padding: '20px' }}>
        <Grid container spacing={3}>
          {buttonsData.map((button, index) => (
            <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
              <div className="cpanel">
                <Typography variant="h6" gutterBottom>
                  {button.label}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="menubuttons1"
                  onClick={() => handleNavigate(button.path)}
                  fullWidth
                >
                  View All
                </Button>
              </div>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Grid>
  );
}

export default ControlPanel;
