import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

// READ ALL
const fetchBills = async () => {
  const { data } = await apiClient.get(`/bill`);
  return data;
};

const fetchBillRegs = async () => {
  const { data } = await apiClient.get(`/bill-reg`);
  return data;
};

export const useReadBills = () =>
  useQuery<any[], Error>({
    queryKey: ['bills', 'list'],
    queryFn: fetchBills,
  });

export const useReadBillRegs = () =>
  useQuery<any[], Error>({
    queryKey: ['bill-regs', 'list'],
    queryFn: fetchBillRegs,
  });

// READ ONE
const fetchBill = async (params: any) => {
  const { data } = await apiClient.get(`/bill/${params.id}`);
  return data;
};

const fetchBillMulti = async (params: any) => {
  const { data: billData } = await apiClient.get(`/bill/${params.id}`);
  const { data: hirechartData } = await apiClient.get(
    `/hirechart/${billData.hid}`
  );
  return {
    bill: billData,
    hirechart: hirechartData,
  };
};

const fetchBillReg = async (params: any) => {
  const { data } = await apiClient.get(`/bill-reg/${params.id}`);
  return data;
};

export const useReadBill = (params: any) =>
  useQuery<any, Error>({
    queryKey: ['bills', 'details', params.id],
    queryFn: () => fetchBill(params),
  });

export const useReadBillMulti = (params: any) =>
  useQuery<any, Error>({
    queryKey: ['bills', 'detailsMulti', params.id],
    queryFn: () => fetchBillMulti(params),
  });

export const useReadBillReg = (params: any) =>
  useQuery<any, Error>({
    queryKey: ['bill-regs', 'details', params.id],
    queryFn: () => fetchBillReg(params),
  });

const getBillRegFiltered = async (req: any) => {
  const params: any = {};

  if (req.startDate) params.startDate = req.startDate;
  if (req.endDate) params.endDate = req.endDate;
  const { data } = await apiClient.post(`/bill-reg/filtered`, params);
  return data;
};

export const useGetBillRegFiltered = (params: any, search: boolean) =>
  useQuery<any, Error>({
    queryKey: ['search', search],
    queryFn: () => getBillRegFiltered(params),
  });

const getBillRegSearch = async (req: any) => {
  const params: any = {};

  if (req.startDate) params.startDate = req.startDate;
  if (req.endDate) params.endDate = req.endDate;
  if (req.keyword) params.keyword = req.keyword;
  if (req.column) params.column = req.column;
  const { data } = await apiClient.post(`/bill-reg/search`, params);
  return data;
};

export const useGetBillRegSearch = (params: any, search: boolean) =>
  useQuery<any, Error>({
    queryKey: ['search', search],
    queryFn: () => getBillRegSearch(params),
  });

// useMutation
export const useAddBill = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-bills'],
    mutationFn: (data: any) => apiClient.post('/bill', data),
  });
  return { mutate };
};

export const useAddBillReg = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-billRegs'],
    mutationFn: (data: any) => apiClient.post('/bill-reg', data),
  });
  return { mutate };
};

export const useGetDBillHireByBillNo = () => {
  const { mutate } = useMutation({
    mutationKey: ['get-dBillHires'],
    mutationFn: (data: any) =>
      apiClient.post('/dbill-hires/billNo', { billnum: data.billNo }),
  });
  return { mutate };
};

export const useAddDBillHire = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-dBillHires'],
    mutationFn: (data: any) => apiClient.post('/dbill-hires', data),
  });
  return { mutate };
};

export const useDeleteDBillHire = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-dBillHire'],
    mutationFn: (data: any) =>
      apiClient.delete(`/dbill-hires/${data.id}`, data),
  });
  return { mutate };
};

export const useUpdateDBillHire = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-dBillHires'],
    mutationFn: (data: any) => apiClient.put(`/dbill-hires/${data.id}`, data),
  });
  return { mutate };
};

const fetchDBillHires = async () => {
  const { data } = await apiClient.get(`/dbill-hires`);
  return data;
};

export const useReadDBillHires = () =>
  useQuery<any[], Error>({
    queryKey: ['dbillHires', 'list'],
    queryFn: fetchDBillHires,
  });

export const useEditBill = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-bills'],
    mutationFn: (data: any) => apiClient.put(`/bill/${data.id}`, data),
  });
  return { mutate };
};

export const useUpdateBill = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-bills'],
    mutationFn: (data: any) => apiClient.put(`/bill/${data.id}`, data),
  });
  return { mutate };
};

export const useUpdateBillReg = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-billRegs'],
    mutationFn: (data: any) => apiClient.put(`/bill-reg/${data.id}`, data),
  });
  return { mutate };
};

export const useUpdateBillRegByBillNo = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-billRegsByBillNo'],
    mutationFn: (data: any) => apiClient.put(`/bill-reg/bill-no`, data),
  });
  return { mutate };
};

export const useDeleteBill = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-bills'],
    mutationFn: (data: any) => apiClient.delete(`/bill/${data.id}`),
  });
  return { mutate };
};

export const useDeleteBillReg = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-billRegs'],
    mutationFn: (data: any) => apiClient.post(`/bill-reg/remove`, data),
  });
  return { mutate };
};

export const useGetBillByHid = () => {
  const { mutate } = useMutation({
    mutationKey: ['get-billByHid'],
    mutationFn: (data: any) => apiClient.post('/bill/hid', { hid: data.hid }),
  });
  return { mutate };
};

export const useUpdateBillByHid = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-billsByHid'],
    mutationFn: (data: any) => apiClient.put(`/bill/hid`, data),
  });
  return { mutate };
};

const getBillRegByLastCreated = async () => {
  const { data } = await apiClient.post(`/bill-reg/last-created`);
  return data;
};

export const useGetBillRegByLastCreated = () =>
  useQuery<any, Error>({
    queryKey: ['billReg-lastCreated'],
    queryFn: () => getBillRegByLastCreated(),
  });
