import { Controller, useFormContext } from 'react-hook-form';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

function EditHotel() {
  const { register } = useFormContext();

  const SectorsList = ['NORTH', 'AVA', 'MORJIM', 'CENTRAL', 'SOUTH'];

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit Hotel
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="hotel-name"
                variant="outlined"
                label="Hotel Name"
                {...register('hotel_name')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="hotel-initial"
                variant="outlined"
                label="Hotel Initials"
                {...register('hotel_initial')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Sector</InputLabel>
                <Controller
                  name="sector"
                  render={({ field }) => (
                    <Select {...field} label="Sector">
                      {SectorsList.map(sector => (
                        <MenuItem key={sector} value={sector}>
                          {sector}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default EditHotel;
