import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IOwnerCode, IReadOwnerCode } from '../../types/controlpanel/ownercode';

// READ ALL
const fetchOwnerCodes = async (): Promise<IOwnerCode[]> => {
  const { data } = await apiClient.get(`/owner-code`);
  return data;
};

export const useReadOwnerCodes = () =>
  useQuery<IOwnerCode[], Error>({
    queryKey: ['ownercode', 'list'],
    queryFn: fetchOwnerCodes,
  });

// READ ONE
const fetchOwnerCode = async (params: IReadOwnerCode): Promise<IOwnerCode> => {
  const { data } = await apiClient.get(`/owner-code/${params.id}`);
  return data;
};

export const useReadOwnerCode = (params: IReadOwnerCode) =>
  useQuery<IOwnerCode, Error>({
    queryKey: ['ownercode', 'details', params.id],
    queryFn: () => fetchOwnerCode(params),
  });

// useMutation
export const useAddOwnerCode = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-ownercode'],
    mutationFn: (data: any) => apiClient.post('/owner-code', data),
  });
  return {
    mutate,
  };
};

export const useEditOwnerCode = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-ownercode'],
    mutationFn: (data: any) => apiClient.put(`/owner-code/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteOwnerCode = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-ownercode'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/owner-code/${id}`),
  });
  return { mutate };
};
