import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddUsers from '@components/ControlPanel/Users/AddUsers';
import EditUsers from '@components/ControlPanel/Users/EditUsers';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddUsers,
  useDeleteUsers,
  useEditUsers,
  useReadUsers,
} from '../../../hooks/controlpanel/users';
import { IUsers } from '../../../types/controlpanel/users';

const Users = () => {
  const { data: users, isLoading, refetch } = useReadUsers();

  const [localuser, setLocalUser] = useState<IUsers[]>([]);
  const [user, setUser] = useState<IUsers | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteUserMutate } = useDeleteUsers();
  const { mutate: addUserMutate } = useAddUsers();
  const { mutate: editUserMutate } = useEditUsers();

  useEffect(() => {
    if (users) {
      setLocalUser(users);
    }
  }, [users]);

  const handleEdit = (rowData: any) => {
    setUser(rowData);
    setDefaultvalue({
      firstname: rowData?.firstname,
      lastname: rowData?.lastname,
      useremail: rowData?.useremail,
      userlevel: rowData?.userlevel,
      password: '',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setUser(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (user && action && action?.action) {
      if (action?.formData?.password === '') {
        delete action.formData.password;
      }
      editUserMutate(
        { ...action?.formData, id: user.userid },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('User Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (user) {
      deleteUserMutate(
        { id: +user.userid },
        {
          onSuccess: () => {
            setLocalUser(prevUser =>
              prevUser.filter(c => c.userid !== user.userid)
            );
            enqueueSnackbar('User Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addUserMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const renderUserRole = (rowData: IUsers) => {
    switch (rowData.userlevel) {
      case '0':
        return 'User';
      case '1':
        return 'Power User';
      case '2':
        return 'Administrator';
      default:
        return 'Unknown';
    }
  };

  const columns: Column<IUsers>[] = [
    {
      title: 'Name',
      field: 'firstname',
    },
    {
      title: 'UserEmail',
      field: 'useremail',
    },
    {
      title: 'Role',
      field: 'userlevel',
      render: rowData => renderUserRole(rowData),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="All Users"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add User
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localuser || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Data',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddUsers}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditUsers}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Users;
