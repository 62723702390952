import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IContract, IReadContract } from '../types/contract';

// READ ALL
const fetchContracts = async (): Promise<IContract[]> => {
  const { data } = await apiClient.get(`/contract`);
  return data;
};

export const useReadContracts = () =>
  useQuery<IContract[], Error>({
    queryKey: ['contracts', 'list'],
    queryFn: () => fetchContracts(),
  });

export const useReadCpart = (cid: string) =>
  useQuery<any[], Error>({
    queryKey: ['cpart', 'list'],
    queryFn: () => fetchCpart(cid),
  });

// READ ONE
const fetchContract = async (params: IReadContract): Promise<IContract> => {
  const { data } = await apiClient.get(`/contract/${params.id}`);
  return data;
};

const fetchContractMulti = async (params: IReadContract) => {
  const { data: contractData } = await apiClient.get(`/contract/${params.id}`);
  const dataToReturn = {
    contract: contractData,
    billReg: null,
  };
  if (
    contractData?.billno === 0 ||
    contractData?.billno === '0' ||
    contractData?.billno === '0' ||
    contractData?.billno === ''
  ) {
    return dataToReturn;
  }
  const { data: billRegData } = await apiClient.get(
    `/bill-reg/${contractData.billno}`
  );

  dataToReturn.billReg = billRegData;
  return dataToReturn;
};

const fetchCpart = async (cid: string) => {
  const { data } = await apiClient.post(`/cpart/cid`, { cid });
  return data;
};

const getCpart = async (id: string) => {
  const { data } = await apiClient.get(`/cpart/${id}`);
  return data;
};

export const useReadCPart = (id: string) =>
  useQuery<IContract, Error>({
    queryKey: ['cpart', 'details', id],
    queryFn: () => getCpart(id),
  });

export const useAddCPart = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-cpart'],
    mutationFn: (data: any) => apiClient.post('/cpart', data),
  });
  return { mutate };
};

export const useEditCPart = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-cpart'],
    mutationFn: (data: any) => apiClient.put(`/cpart/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteCPart = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-cpart'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/cpart/${id}`),
  });
  return { mutate };
};

export const useReadContract = (params: IReadContract) =>
  useQuery<IContract, Error>({
    queryKey: ['contract', 'details', params.id],
    queryFn: () => fetchContract(params),
  });

export const useReadContractMulti = (params: IReadContract) =>
  useQuery<any, Error>({
    queryKey: ['contract', 'details-multi', params.id],
    queryFn: () => fetchContractMulti(params),
  });

// useMutation
export const useAddContract = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-contracts'],
    mutationFn: (data: any) => apiClient.post('/contract', data),
  });
  return { mutate };
};

export const useEditContract = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-contracts'],
    mutationFn: (data: any) => apiClient.put(`/contract/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteContract = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-contracts'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/contract/${id}`),
  });
  return { mutate };
};

export const useUpdateBillReg = () => {
  const { mutate } = useMutation({
    mutationKey: ['update-billreg'],
    mutationFn: (data: any) => apiClient.put(`/bill-reg/${data.id}`, data),
  });
  return { mutate };
};
