import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddCompanyRates,
  useDeleteCompanyRates,
  useEditCompanyRates,
  useGetCompanyRates,
  useReadNCompany,
} from '../../../../hooks/controlpanel/newcompanies';
import { INCompanyRates } from '../../../../types/controlpanel/newcompanies';
import AddCompanyRates from './AddCompanyRates';
import EditCompanyRates from './EditCompanyRates';

const CompanyTransferRates = () => {
  const [srates, setSrates] = useState<INCompanyRates | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [minTypeText, setMinTypeText] = useState('8 HRs / 80 KMs');

  const params = useParams();
  const { data: companyDetails, isLoading } = useReadNCompany({
    id: params?.id ?? '',
  });

  useEffect(() => {
    if (companyDetails && companyDetails.mintype === '1') {
      setMinTypeText('8 HRs / 100 KMs');
    }
  }, [companyDetails]);

  const { data: rateListing, refetch: refetchRates } = useGetCompanyRates({
    companyName: companyDetails?.name ?? '',
  });
  const { mutate: addratesMutate } = useAddCompanyRates();
  const { mutate: editratesMutate } = useEditCompanyRates();
  const { mutate: deleteratesMutate } = useDeleteCompanyRates();

  const handleEdit = (rowData: any) => {
    setSrates(rowData);
    setDefaultvalue({
      vid: rowData?.vid,
      cid: companyDetails?.name,
      mintype: rowData?.mintype,
      initial: rowData?.initial,
      xkm: rowData?.xkm,
      xhr: rowData?.xhr,
      late: rowData?.late,
      onite: rowData?.onite,
      early: rowData?.early,
      early_morning: rowData?.early_morning,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setSrates(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (srates && action && action?.action) {
      editratesMutate(
        {
          ...action?.formData,
          id: srates.id,
          cid: companyDetails?.name ?? '',
        },
        {
          onSuccess: () => {
            refetchRates();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (srates) {
      deleteratesMutate(
        { id: +srates.id },
        {
          onSuccess: () => {
            refetchRates();
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addratesMutate(
        {
          ...action?.formData,
          cid: companyDetails?.name ?? '',
        },
        {
          onSuccess: () => {
            refetchRates();
            setAddDialogOpen(false);
          },
          onError: (err: any) => {
            if (err?.response?.data?.message === 'Rate already exists') {
              enqueueSnackbar('Rate already exists', { variant: 'error' });
            } else {
              enqueueSnackbar('Failed to Add content', { variant: 'error' });
            }
          },
        }
      );
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<INCompanyRates>[] = [
    {
      title: 'Vehicle Type',
      field: 'vid',
    },
    {
      title: minTypeText,
      field: 'mintype',
    },
    {
      title: 'Extra Km',
      field: 'xkm',
    },
    {
      title: 'Extra Hr',
      field: 'xhr',
    },
    {
      title: 'Early',
      field: 'early',
    },
    {
      title: 'Extra Morning',
      field: 'early_morning',
    },
    {
      title: 'Late',
      field: 'late',
    },
    {
      title: 'Overnight',
      field: 'onite',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Sightseeing / Disposal Rates"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Rates
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={rateListing || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Data',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddCompanyRates}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditCompanyRates}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default CompanyTransferRates;
