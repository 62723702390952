import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

// import ContactDetail from '@components/Contact/ContactDetail';
import AddBillDates from '@components/ControlPanel/BillDates/AddBillDates';
import EditBillDates from '@components/ControlPanel/BillDates/EditBillDates';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
// import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddBillDates,
  useDeleteBillDates,
  useEditBillDates,
  useReadBillDates,
} from '../../../hooks/controlpanel/billdates';
import { IBillDates } from '../../../types/controlpanel/billdates';

const BillDates = () => {
  const { data: billdates, isLoading, refetch } = useReadBillDates();

  const [localbilldate, setLocalBilldate] = useState<IBillDates[]>([]);
  const [billdate, setBilldate] = useState<IBillDates | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deletebilldateMutate } = useDeleteBillDates();
  const { mutate: addbilldateMutate } = useAddBillDates();
  const { mutate: editbilldateMutate } = useEditBillDates();

  useEffect(() => {
    if (billdates) {
      setLocalBilldate(billdates);
    }
  }, [billdates]);

  const handleEdit = (rowData: any) => {
    setBilldate(rowData);
    setDefaultvalue({
      billdate: rowData?.billdate,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setBilldate(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (billdate && action && action?.action) {
      editbilldateMutate(
        { ...action?.formData, id: billdate.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (billdate) {
      deletebilldateMutate(
        { id: +billdate.id },
        {
          onSuccess: () => {
            setLocalBilldate(prevBilldate =>
              prevBilldate.filter(c => c.id !== billdate.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addbilldateMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const formatDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateStr;
    }
  };

  const columns: Column<IBillDates>[] = [
    {
      title: 'Date',
      field: 'billdate',
      defaultSort: 'desc',
      render: rowData => formatDate(rowData.billdate),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="BillDates"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Bill Details
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localbilldate || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddBillDates}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditBillDates}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default BillDates;
