import { useMutation } from '@tanstack/react-query';

import apiClient from '@constants/axios';

export const useSetDbYear = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-dbYear'],
    mutationFn: (data: any) =>
      apiClient.post(`/set-db-year`, { year: data.year }),
  });
  return {
    mutate,
  };
};
