import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import EditPdcHire from '@components/HireCharts/EditPdcHire';
import HireChartDetails from '@components/HireCharts/HireChartDetails';
import PageTitle from '@components/PageTitle';

import { useReadNCompanys } from '../../hooks/controlpanel/newcompanies';
import {
  useDeleteHireChart,
  useEditHireChart,
  useFilterHireChart,
} from '../../hooks/hirechart';
import { IHireChart } from '../../types/hirechart';

const SearchBy = [
  {
    label: 'Sub Agent',
    value: 'subagent',
  },
  {
    label: 'Client Name',
    value: 'client',
  },
  {
    label: 'Owner Code',
    value: 'owncode',
  },
  {
    label: 'Veh. No.',
    value: 'vno',
  },
];

const EditHireChart = () => {
  const { data: companyList = [] } = useReadNCompanys();

  const [localhirechart, setLocalHirechart] = useState<IHireChart[]>([]);
  const [hirechart, setHireChart] = useState<IHireChart | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteHirechartMutate } = useDeleteHireChart();
  const { mutate: editHirechartMutate } = useEditHireChart();
  const { mutate: filterHireChartMutate, isPending: isLoading } =
    useFilterHireChart();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: (() => {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          2
        );
        return firstDayOfMonth.toISOString().split('T')[0];
      })(),
      endDate: (() => {
        const today = new Date();
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        );
        return lastDayOfMonth.toISOString().split('T')[0];
      })(),
      company: '',
      hirechartType: [],
      searchBy: '',
      searchValue: '',
    },
  });

  const formatDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateStr;
    }
  };

  const getELO = (item: any) => {
    let elo = '';
    if (item.early === '1') elo = 'E';
    if (item.early_morning === '1') elo = 'EM';
    if (item.late === '1') elo += ' L';
    if (item.onite === '1') elo += ' O';
    return elo.trim();
  };

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setHireChart(rowData);
      setDrawerOpen(true);
    }
  };
  const handleEdit = (rowData: any) => {
    setHireChart(rowData);
    // if (rowData.client === 'EX') {
    setDefaultvalue({
      date: rowData?.date,
      company: rowData?.company,
      ac: rowData?.ac,
      vtype: rowData?.vtype,
      ocode: rowData?.ocode,
      vno: rowData?.vno,
      driver: rowData?.driver,
      cleaner: rowData?.cleaner,
      hiretype: rowData?.hiretype,
      client: rowData?.client,
      subagent: rowData?.subagent,
      repveh: rowData?.repveh,
      rvocode: rowData?.rvocode,
      okm: rowData?.okm,
      ckm: rowData?.ckm,
      tkm: rowData?.tkm,
      akm: rowData?.akm,
      ttkm: rowData?.ttkm,
      bill: rowData?.bill,
      billno: rowData?.billno,
      toll: rowData?.toll,
      break: rowData?.break,
      remark: rowData?.remark,
      particulars: rowData?.particulars,
      particulars_type: rowData?.particulars_type,
      early: rowData?.early === '1',
      early_morning: rowData?.early_morning === '1',
      late: rowData?.late === '1',
      onite: rowData?.onite === '1',
    });
    // }
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setHireChart(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (hirechart && action && action?.action) {
      editHirechartMutate(
        { ...action?.formData, id: +hirechart.id },
        {
          onSuccess: () => {
            handleSearch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (hirechart) {
      deleteHirechartMutate(
        { id: +hirechart.id },
        {
          onSuccess: () => {
            setLocalHirechart(prevHirechart =>
              prevHirechart.filter(c => c.id !== hirechart.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const convertToCSV = (data: IHireChart[]) => {
    const csvContent = 'data:text/csv;charset=utf-8,';
    const headers = Object.keys(data[0]).join(',');
    const rows = data
      .map(entry => {
        const formattedEntry = { ...entry };
        if (formattedEntry.date) {
          formattedEntry.date = `"${new Date(formattedEntry.date).toLocaleDateString('en-GB')}"`;
        }
        return Object.values(formattedEntry).join(',');
      })
      .join('\n');
    return `${csvContent + headers}\n${rows}`;
  };

  const handleSaveToCSV = () => {
    const csvData = convertToCSV(localhirechart);
    const encodedURI = encodeURI(csvData);
    const link = document.createElement('a');
    link.setAttribute('href', encodedURI);
    link.setAttribute('download', 'hirechart_data.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleSearch = handleSubmit((formData: any) => {
    const filterCriteria = {
      ...(formData?.startDate && { startDate: formData?.startDate }),
      ...(formData?.endDate && { endDate: formData?.endDate }),
      ...(formData?.company && { company: formData?.company }),
    };
    if (formData?.hirechartType && formData.hirechartType.length > 0) {
      filterCriteria.hirechartType = formData.hirechartType;
    }

    if (formData.searchBy && formData.searchValue) {
      filterCriteria[formData.searchBy] = formData.searchValue ?? '';
    }

    filterHireChartMutate(filterCriteria, {
      onSuccess: (filteredData: any) => {
        if (filteredData?.data) {
          const processedData = filteredData?.data?.map((item: any) => ({
            ...item,
            elo: getELO(item),
          }));
          setLocalHirechart(processedData);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch filtered data', {
          variant: 'error',
        });
      },
    });
  });

  useEffect(() => {
    document.title = 'Hire Chart - nunes';
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Column<IHireChart>[] = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => formatDate(rowData.date),
      cellStyle: {
        maxWidth: '100px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '100px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Company',
      field: 'company',
      cellStyle: {
        maxWidth: '150px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '150px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Veh Type',
      field: 'vtype',
      cellStyle: {
        maxWidth: '100px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '100px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Own Code',
      field: 'ocode',
      cellStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Veh No.',
      field: 'vno',
      cellStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Rep Veh',
      field: 'repveh',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Rep Own Code',
      field: 'rvocode',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Particulars',
      field: 'particulars_type',
      cellStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Hire Type',
      field: 'hiretype',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Sub Agent',
      field: 'subagent',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Client',
      field: 'client',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'E/EM/L/O',
      field: 'elo',
      cellStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '80px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Toll',
      field: 'toll',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
    {
      title: 'Bill No.',
      field: 'billno',
      cellStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
      headerStyle: {
        maxWidth: '50px',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
        fontSize: '0.7rem',
      },
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Edit Hire"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveToCSV}
            >
              SAVE To CSV
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Accordion style={{ padding: '10px' }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: 'primary.main',
                    '&.Mui-expanded': { transform: 'rotate(180deg)' },
                  }}
                />
              }
              aria-controls="filter-content"
              id="filter-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            >
              <Typography variant="h6">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="From Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="To Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ width: '100%' }}
                    >
                      <InputLabel id="company-select-label">
                        Select Company
                      </InputLabel>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="company-select-label"
                            label="Select Company"
                          >
                            <MenuItem value="">
                              <em>Select Company</em>
                            </MenuItem>
                            {companyList?.map((comp: any) => (
                              <MenuItem key={comp?.id} value={comp?.name}>
                                {comp?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="searchBy">Search By</InputLabel>
                      <Controller
                        control={control}
                        name="searchBy"
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Search By"
                            inputProps={{
                              id: 'searchBy',
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Search</em>
                            </MenuItem>
                            {SearchBy?.map((comp: any) => (
                              <MenuItem key={comp?.value} value={comp?.value}>
                                {comp?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="searchValue"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Search Value"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: false }}
                          type="text"
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    {['DEP', 'ARR', 'D/A', 'S/S', 'S/D', 'A/S', 'DISP'].map(
                      (option: any, index: number) => (
                        <Grid key={index}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="hirechartType"
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    onChange={e =>
                                      field.onChange(
                                        e.target.checked
                                          ? [...field.value, option]
                                          : field.value.filter(
                                              item => item !== option
                                            )
                                      )
                                    }
                                    color="primary"
                                    value={option}
                                  />
                                )}
                              />
                            }
                            label={option}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localhirechart || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: false,
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
                Toolbar: () => <div style={{ margin: '10px' }}> </div>,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={HireChartDetails}
        data={hirechart}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditPdcHire}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default EditHireChart;
