import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const EditUsers: React.FC = () => {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5">Edit User</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="First Name"
                {...register('firstname')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Last Name"
                {...register('lastname')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Email"
                {...register('useremail')}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Password"
                type="password"
                {...register('password')}
                fullWidth
                margin="normal"
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Select label="User Level" {...register('userlevel')} fullWidth>
                <MenuItem value="0">User</MenuItem>
                <MenuItem value="1">Power User</MenuItem>
                <MenuItem value="2">Administrator</MenuItem>
              </Select>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="user-level-label">User Level</InputLabel>
                <Controller
                  name="userlevel"
                  defaultValue="2"
                  render={({ field }) => (
                    <Select
                      labelId="user-level-label"
                      label="User Level"
                      {...field}
                    >
                      {/* <MenuItem value="0">User</MenuItem>
                      <MenuItem value="1">Power User</MenuItem> */}
                      <MenuItem value="2">Administrator</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default EditUsers;
