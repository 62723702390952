import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IReadTransfer, ITransfer } from '../../types/controlpanel/transfer';

// READ ALL
const fetchTransfers = async (): Promise<ITransfer[]> => {
  const { data } = await apiClient.get(`/transfer`);
  return data;
};

export const useReadTransfers = () =>
  useQuery<ITransfer[], Error>({
    queryKey: ['transfer', 'list'],
    queryFn: fetchTransfers,
  });

// READ ONE
const fetchTransfer = async (params: IReadTransfer): Promise<ITransfer> => {
  const { data } = await apiClient.get(`/transfer/${params.id}`);
  return data;
};

export const useReadTransfer = (params: IReadTransfer) =>
  useQuery<ITransfer, Error>({
    queryKey: ['transfer', 'details', params.id],
    queryFn: () => fetchTransfer(params),
  });

// useMutation
export const useAddTransfer = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-transfer'],
    mutationFn: (data: any) => apiClient.post('/transfer', data),
  });
  return {
    mutate,
  };
};

export const useEditTransfer = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-transfer'],
    mutationFn: (data: any) => apiClient.put(`/transfer/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteTransfer = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-transfer'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/transfer/${id}`),
  });
  return { mutate };
};
