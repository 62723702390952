import { useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

const fetchSubagents = async () => {
  const { data } = await apiClient.get(`/sub-agent`);
  return data;
};

export const useReadSubagents = () =>
  useQuery<any[], Error>({
    queryKey: ['subagents', 'list'],
    queryFn: fetchSubagents,
  });
