import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirmDelete: () => void;
}

const DeleteDialog = ({
  open,
  handleClose,
  handleConfirmDelete,
}: DeleteDialogProps) => (
  <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>Delete Alert</DialogTitle>
    <DialogContent>
      <Alert severity="warning">Are you sure you want to delete ?</Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        Cancel
      </Button>
      <Button onClick={handleConfirmDelete} color="error" variant="contained">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
