import { useEffect, useState } from 'react';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useReadTransfers } from '../../../../hooks/controlpanel/transfer';

function EditCompanyTariff() {
  const { register, control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  });

  const { data: transfers } = useReadTransfers();

  const tariffListing = JSON.parse(
    localStorage.getItem('companytariffListingDataFromApi') || '[]'
  );

  const [selectedTransferType, setSelectedTransferType] = useState<any>(
    getValues('tid') || null
  );
  const [ttypeData, setTtypeData] = useState<any>(null);

  const clearFields = () => {
    fields.forEach((_, index) => remove(index));
  };

  useEffect(() => {
    if (selectedTransferType) {
      const ttype = transfers?.find(
        (transfer: any) => transfer.id === selectedTransferType
      );
      setTtypeData(ttype);
    }
  }, [selectedTransferType, transfers]);

  useEffect(() => {
    if (ttypeData?.type) {
      const filterTtpeDataFromTariffListing = tariffListing?.filter(
        (item: any) => item.ttype === ttypeData?.type
      );
      clearFields();
      append(filterTtpeDataFromTariffListing || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ttypeData]);

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit Company Tariff
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="tid-label">Transfer Type</InputLabel>
                <Controller
                  name="tid"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Transfer Type"
                      onChange={e => {
                        const newValue = e.target.value;
                        setSelectedTransferType(newValue);
                        field.onChange(e);
                      }}
                      defaultValue={selectedTransferType || ''}
                    >
                      {transfers?.map(transfer => (
                        <MenuItem key={transfer.id} value={transfer.id}>
                          {transfer?.type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            {fields?.map((vtype: any, index: number) => (
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                key={vtype.id}
              >
                <TextField
                  id={`vid_${index}`}
                  variant="outlined"
                  disabled
                  {...register(`data[${index}].vid`)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={vtype.vid}
                />
                <TextField
                  id={`rate_${index}`}
                  variant="outlined"
                  label={`Rate for ${vtype.vid}`}
                  {...register(`data[${index}].rate`)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={vtype.rate}
                />
              </div>
            ))}
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default EditCompanyTariff;
