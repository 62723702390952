import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddOwnerCode from '@components/ControlPanel/OwnerCode/AddOwnerCode';
import EditOwnerCode from '@components/ControlPanel/OwnerCode/EditOwnerCode';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddOwnerCode,
  useDeleteOwnerCode,
  useEditOwnerCode,
  useReadOwnerCodes,
} from '../../../hooks/controlpanel/ownercode';
import { IOwnerCode } from '../../../types/controlpanel/ownercode';

const OwnerCode = () => {
  const { data: ocodes, isLoading, refetch } = useReadOwnerCodes();

  const [localocode, setLocalOcode] = useState<IOwnerCode[]>([]);
  const [ocode, setOcode] = useState<IOwnerCode | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteOcodeMutate } = useDeleteOwnerCode();
  const { mutate: addOcodeMutate } = useAddOwnerCode();
  const { mutate: editOcodeMutate } = useEditOwnerCode();

  useEffect(() => {
    if (ocodes) {
      setLocalOcode(ocodes);
    }
  }, [ocodes]);

  const handleEdit = (rowData: any) => {
    setOcode(rowData);
    setDefaultvalue({
      code: rowData?.code,
      info: rowData?.info,
      mobile: rowData?.mobile,
      phone: rowData?.phone,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setOcode(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (ocode && action && action?.action) {
      editOcodeMutate(
        { ...action?.formData, id: ocode.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (ocode) {
      deleteOcodeMutate(
        { id: +ocode.id },
        {
          onSuccess: () => {
            setLocalOcode(prevOcode =>
              prevOcode.filter(c => c.id !== ocode.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addOcodeMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IOwnerCode>[] = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Info',
      field: 'info',
    },
    {
      title: 'Mobile',
      field: 'mobile',
    },
    {
      title: 'Phone',
      field: 'phone',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Owner Codes"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add OWN Code
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localocode || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddOwnerCode}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditOwnerCode}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default OwnerCode;
