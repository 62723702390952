import { createTheme, responsiveFontSizes } from '@mui/material';
import { green } from '@mui/material/colors';

let defaultTheme = createTheme({
  palette: {
    primary: {
      main: green[300],
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: green[50],
    },
  },
  components: {},
});

defaultTheme = responsiveFontSizes(defaultTheme);

const { breakpoints } = defaultTheme;

const theme = {
  ...defaultTheme,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem 1.25rem',
          borderRadius: '2rem',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          backgroundColor: defaultTheme.palette.background.default,
          [breakpoints.down('sm')]: {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.primary.light,
            borderRadius: '0 2rem 2rem 0',
            color: 'white',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: 'transparent',
          [breakpoints.down('sm')]: {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: '0px',
          textAlign: 'center !important',
          fontSize: '0.6rem',
          fontWeight: 'bold',
          borderRight: '1px solid #ccc',
          maxWidth: '80px',
        },
        body: {
          padding: '0px',
          fontSize: '0.7rem !important',
          textAlign: 'center',
          borderRight: '1px solid #ccc',
          maxWidth: '80px',
        },
      },
    },
  },
};

export default theme;
