import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IProfile } from '../types/profile';

// READ ALL
const fetchProfiles = async (): Promise<IProfile> => {
  const { data } = await apiClient.get(`user/profile`);
  return data;
};

export const useReadProfiles = () =>
  useQuery<IProfile, Error>({
    queryKey: ['profile', 'list'],
    queryFn: () => fetchProfiles(),
  });

// useMutation
export const useEditProfile = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-profile'],
    mutationFn: (data: any) => apiClient.put('user/profile', data),
  });
  return { mutate };
};
