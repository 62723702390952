import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddGeneralTariff,
  useDeleteGeneralTariff,
  useEditGeneralTariff,
  useReadGeneralTariffs,
} from '../../../../hooks/controlpanel/genraltariff';
import { IGeneralTariff } from '../../../../types/controlpanel/generaltariff';
import AddGeneralTariff from './AddGeneralTariff';
import EditGeneralTariff from './EditGeneralTariff';

const GeneralTransferTariff = () => {
  const { data: stariffs, isLoading, refetch } = useReadGeneralTariffs();

  const [localstariff, setLocalStariff] = useState<IGeneralTariff[]>([]);
  const [stariff, setStariff] = useState<IGeneralTariff | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addStariffMutate } = useAddGeneralTariff();
  const { mutate: editStariffMutate } = useEditGeneralTariff();
  const { mutate: deleteStariffMutate } = useDeleteGeneralTariff();

  useEffect(() => {
    if (stariffs) {
      setLocalStariff(stariffs);
    }
  }, [stariffs]);

  const handleEdit = (rowData: any) => {
    setStariff(rowData);
    setDefaultvalue({
      vid: rowData?.vid,
      // data: [
      //   {
      tid: rowData?.tid ?? '',
      rate: rowData?.rate ?? '',
      id: rowData?.id ?? '',
      //   },
      // ],
    });
    setEditDialogOpen(true);
  };

  // const handleDelete = (rowData: any) => {
  //   setStariff(rowData);
  //   setDeleteDialogOpen(true);
  //   enqueueSnackbar('Delete Modal', { variant: 'warning' });
  // };

  const handleCloseEditDialog = (action: any) => {
    if (stariff && action && action?.action) {
      editStariffMutate(
        {
          id: null,
          vid: action?.formData.vid,
          data: [
            {
              id: stariff.id,
              tid: action?.formData.tid,
              rate: action?.formData.rate,
            },
          ],
        },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (stariff) {
      deleteStariffMutate(
        { id: +stariff.id },
        {
          onSuccess: () => {
            setLocalStariff(prevStariff =>
              prevStariff.filter(c => c.id !== stariff.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addStariffMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: (err: any) => {
          if (
            err?.response?.data?.message === 'General tariff already exists'
          ) {
            enqueueSnackbar('General tariff already exists', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Failed to Add content', { variant: 'error' });
          }
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IGeneralTariff>[] = [
    {
      title: 'Transfer Type',
      field: 'ttype',
    },
    {
      title: 'Vehicle Type',
      field: 'vid',
    },
    {
      title: 'Rate',
      field: 'rate',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Transfer Type"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Tariff
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localstariff || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                // {
                //   icon: DeleteIcon,
                //   tooltip: 'Delete Data',
                //   onClick: (event, rowData) => handleDelete(rowData),
                // },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddGeneralTariff}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditGeneralTariff}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default GeneralTransferTariff;
