import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddSubAgentTariff,
  useDeleteSubAgentTariff,
  useEditSubAgentTariff,
  useGetSubAgentTariff,
  useReadSubAgent,
} from '../../../../hooks/controlpanel/subagent';
import { ISubAgentTariff } from '../../../../types/controlpanel/subagent';
import AddSubAgentTariff from './AddSubAgentTariff';
import EditSubAgentTariff from './EditSubAgentTariff';

const SubAgentTransferTariff = () => {
  const [stariff, setStariff] = useState<ISubAgentTariff | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const params = useParams();

  const { data: subAgentDetails, isLoading } = useReadSubAgent({
    id: params?.id ?? '',
  });

  const { data: tariffListing, refetch: refetchTariff } = useGetSubAgentTariff({
    subAgentName: subAgentDetails?.name ?? '',
  });

  const { mutate: addStariffMutate } = useAddSubAgentTariff();
  const { mutate: editStariffMutate } = useEditSubAgentTariff();
  const { mutate: deleteStariffMutate } = useDeleteSubAgentTariff();

  const [columns, setColumns] = useState<Column<any>[]>([]);
  const [transformedData, setTransformedData] = useState<any[]>([]);

  useEffect(() => {
    if (tariffListing) {
      const filteredTariffListing = tariffListing.filter(
        (item: any) => parseFloat(item.rate) !== 0
      );

      const vehicleTypes = Array.from(
        new Set(filteredTariffListing.map((item: any) => item.vid))
      );

      const transferTypes = Array.from(
        new Set(filteredTariffListing.map((item: any) => item.ttype))
      );

      const data = transferTypes.map(ttype => {
        const row: any = { ttype };
        vehicleTypes.forEach((vid: any) => {
          const rateObj = filteredTariffListing.find(
            (item: any) => item.ttype === ttype && item.vid === vid
          );

          row[vid] = rateObj ? rateObj.rate : '';
          row.tid = rateObj ? rateObj.tid : '';
          row.id = rateObj ? rateObj.id : '';
          row.subAgentName = subAgentDetails?.name ?? '';
        });
        return row;
      });

      setColumns([
        { title: 'Transfer Type', field: 'ttype' },
        ...vehicleTypes.map((vid: any) => ({
          title: vid,
          field: vid,
        })),
      ]);

      setTransformedData(data);
    }
  }, [subAgentDetails?.name, tariffListing]);

  const handleEdit = (rowData: any) => {
    localStorage.setItem(
      'subagenttariffListingDataFromApi',
      JSON.stringify(tariffListing)
    );
    setStariff(rowData);
    setDefaultvalue({
      vid: rowData?.vid,
      tid: rowData?.tid ?? '',
      rate: rowData?.rate ?? '',
      ttype: rowData?.ttype ?? '',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    console.log(rowData);
    setStariff(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (stariff && action && action?.action) {
      editStariffMutate(
        {
          data: action?.formData?.data || [],
          id: stariff.id,
        },
        {
          onSuccess: () => {
            refetchTariff();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (stariff) {
      console.log(stariff);
      deleteStariffMutate(
        {
          cid: stariff.subAgentName,
          tid: stariff.tid,
        },
        {
          onSuccess: () => {
            refetchTariff();
            enqueueSnackbar('Content Deleted', { variant: 'success' });
            setDeleteDialogOpen(false);
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addStariffMutate(
        {
          ...action?.formData,
          cid: subAgentDetails?.name ?? '',
        },
        {
          onSuccess: () => {
            refetchTariff();
            setAddDialogOpen(false);
          },
          onError: () => {
            enqueueSnackbar('Failed to Add content', { variant: 'error' });
          },
        }
      );
    } else {
      setAddDialogOpen(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Transfer Type"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Tariff
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={transformedData}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Data',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddSubAgentTariff}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditSubAgentTariff}
        billMade={false}
        // tariffListingDataFromApi={tariffListing}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default SubAgentTransferTariff;
