import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IReadSubAgent, ISubAgent } from '../../types/controlpanel/subagent';

// READ ALL
const fetchSubAgents = async (): Promise<ISubAgent[]> => {
  const { data } = await apiClient.get(`/sub-agent`);
  return data;
};

export const useReadSubAgents = () =>
  useQuery<ISubAgent[], Error>({
    queryKey: ['sub-agent', 'list'],
    queryFn: fetchSubAgents,
  });

// READ ONE
const fetchSubAgent = async (params: IReadSubAgent): Promise<ISubAgent> => {
  const { data } = await apiClient.get(`/sub-agent/${params.id}`);
  return data;
};

export const useReadSubAgent = (params: IReadSubAgent) =>
  useQuery<ISubAgent, Error>({
    queryKey: ['sub-agent', 'details', params.id],
    queryFn: () => fetchSubAgent(params),
  });

// useMutation
export const useAddSubAgent = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-subagent'],
    mutationFn: (data: any) => apiClient.post('/sub-agent', data),
  });
  return {
    mutate,
  };
};

export const useEditSubAgent = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-subagent'],
    mutationFn: (data: any) => apiClient.put(`/sub-agent/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteSubAgent = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-subagent'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/sub-agent/${id}`),
  });
  return { mutate };
};

// SubAgentTariff

// useMutation

export const useGetSubAgentTariff = ({
  subAgentName,
}: {
  subAgentName: string | undefined;
}) => {
  const { data, refetch } = useQuery({
    queryKey: ['get-subagent-tariff'],
    queryFn: () =>
      apiClient.post('/subagent/tariff', {
        cid: subAgentName,
      }),
    enabled: !!subAgentName,
  });
  return {
    data: data?.data ?? [],
    refetch,
  };
};

export const useAddSubAgentTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/subagent/tariff'],
    mutationFn: (data: any) => apiClient.post(`/tariff`, data),
  });
  return { mutate };
};

export const useEditSubAgentTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/subagent/tariff'],
    mutationFn: (data: any) => apiClient.put(`/tariff/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteSubAgentTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/subagent/tariff'],
    mutationFn: (data: any) => apiClient.post(`/tariff/delete-row`, data),
  });
  return { mutate };
};

// SubAgentRates

// useMutation
export const useGetSubAgentRates = ({
  subAgentName,
}: {
  subAgentName: string;
}) => {
  const { data, refetch } = useQuery({
    queryKey: ['get-subagent-rates'],
    queryFn: () =>
      apiClient.post('/subagent/rates', {
        cid: subAgentName,
      }),
    enabled: !!subAgentName,
  });
  return {
    data: data?.data ?? [],
    refetch,
  };
};

export const useAddSubAgentRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/rates'],
    mutationFn: (data: any) => apiClient.post(`/rates`, data),
  });
  return { mutate };
};

export const useEditSubAgentRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/rates'],
    mutationFn: (data: any) => apiClient.put(`/rates/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteSubAgentRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/rates'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/rates/${id}`),
  });
  return { mutate };
};
