import { Controller, useFormContext } from 'react-hook-form';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions<any>();

export default function SelectionDropdown({
  name,
  options,
  label,
  renderLabel,
  displayLabel,
  setSelectedValue,
}: {
  name: string;
  options: any;
  label: string;
  renderLabel: (option: any) => string;
  setSelectedValue: (option: any) => any;
  displayLabel: string;
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        // Sort options alphabetically by displayLabel
        const sortedOptions = options
          .slice()
          .sort((a: any, b: any) =>
            a[displayLabel].localeCompare(b[displayLabel])
          );

        return (
          <Autocomplete
            {...field}
            value={field.value || null}
            onChange={(_, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedValue({ [displayLabel]: newValue });
                field.onChange(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedValue({ [displayLabel]: newValue.inputValue });
                field.onChange(newValue.inputValue);
              } else {
                setSelectedValue(newValue);
                field.onChange(newValue ? newValue[displayLabel] : null);
              }
            }}
            filterOptions={(autoOptions, params) => {
              const filtered = filter(autoOptions, params);
              const { inputValue } = params;
              const isExisting = autoOptions.some(
                (option: any) => inputValue === option[displayLabel]
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  [displayLabel]: `Add "${inputValue}"`,
                  value: inputValue,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={sortedOptions}
            getOptionLabel={(option: any) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option[displayLabel] ?? '';
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {renderLabel(option)}
              </li>
            )}
            freeSolo
            renderInput={params => <TextField {...params} label={label} />}
          />
        );
      }}
    />
  );
}
