import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

// import ContactDetail from '@components/Contact/ContactDetail';
// import EditCompanies from '@components/ControlPanel/Companies/EditCompanies';
import AddSubAgent from '@components/ControlPanel/SubAgent/AddSubAgent';
import EditSubAgent from '@components/ControlPanel/SubAgent/EditSubAgent';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddSubAgent,
  useDeleteSubAgent,
  useEditSubAgent,
  useReadSubAgents,
} from '../../../hooks/controlpanel/subagent';
import { ISubAgent } from '../../../types/controlpanel/subagent';

const SubAgent = () => {
  const { data: subagents, isLoading, refetch } = useReadSubAgents();

  const [localagent, setLocalAgent] = useState<ISubAgent[]>([]);
  const [agent, setAgent] = useState<ISubAgent | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteSubagentMutate } = useDeleteSubAgent();
  const { mutate: addSubagentMutate } = useAddSubAgent();
  const { mutate: editSubagentMutate } = useEditSubAgent();

  useEffect(() => {
    if (subagents) {
      setLocalAgent(subagents);
    }
  }, [subagents]);

  const handleEdit = (rowData: any) => {
    setAgent(rowData);
    setDefaultvalue({
      name: rowData?.name,
      info: rowData?.info,
      edate: rowData?.edate,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setAgent(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (agent && action && action?.action) {
      editSubagentMutate(
        { ...action?.formData, id: agent.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  // const handleCloseDeleteDialog = () => {
  //   setDeleteDialogOpen(false);
  // };

  const handleConfirmDelete = () => {
    if (agent) {
      deleteSubagentMutate(
        { id: +agent.id },
        {
          onSuccess: () => {
            setLocalAgent(prevAgent =>
              prevAgent.filter(c => c.id !== agent.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  // const handleCloseEditDialog = () => {
  //   setEditDialogOpen(false);
  // };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // const handleConfirmDelete = () => {
  //   mutate(
  //     {
  //       data: 'hello delete subagents',
  //     },
  //     {
  //       onSuccess: data => {},
  //       onError: error => {},
  //     }
  //   );
  //   setDeleteDialogOpen(false);
  //   enqueueSnackbar('Contact Deleted', { variant: 'success' });
  // };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addSubagentMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<ISubAgent>[] = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Info',
      field: 'info',
    },
    {
      title: 'Current Tariff',
      render: rowData => (
        <Link to={`/subAgentTariff/${rowData.id}`}>Tariff</Link>
      ),
    },
    {
      title: 'Eff. Date',
      field: 'edate',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Sub Agents"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Sub Agents
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localagent || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Data',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddSubAgent}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditSubAgent}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default SubAgent;
