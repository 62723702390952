import { useEffect } from 'react';

import HomeDashboard from '@components/Home/Home';

const Home = () => {
  useEffect(() => {
    document.title = 'Home - nunes';
  }, []);

  return (
    <div>
      <HomeDashboard />
    </div>
  );
};

export default Home;
