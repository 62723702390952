import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

interface AddDialogProps {
  open: boolean;
  handleClose: (e: any) => void;
  ContentComponent: any;
}

const AddDialog: React.FC<AddDialogProps> = ({
  open,
  handleClose,
  ContentComponent,
}: AddDialogProps) => {
  const methods = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (formData: any) => {
    console.log(formData);
    if (formData?.repeat) {
      handleClose({
        action: true,
        formData,
        closeDialog: false,
      });
    } else {
      handleClose({
        action: true,
        formData,
        closeDialog: true,
      });
      setTimeout(() => {
        reset();
      }, 1000);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(_: any, reason: any) => {
        if (reason !== 'backdropClick') {
          handleClose(false);
        }
      }}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ backgroundColor: '#f2f2f2' }}>
            <ContentComponent />
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#f2f2f2' }}>
            <Button
              onClick={() => handleClose(false)}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddDialog;
