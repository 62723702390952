import React, { useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Box, Button, Card, Grid, Tab, Tabs, Typography } from '@mui/material';

import CompanyTransferRates from '@components/ControlPanel/Companies/CompanyRates/CompanyTransferRates';
import CompanyTransferTariff from '@components/ControlPanel/Companies/CompanyTariff/CompanyTransferTariff';
import PageTitle from '@components/PageTitle';

import {
  useGetCompanyRates,
  useGetCompanyTariff,
  useReadNCompany,
} from '../../../hooks/controlpanel/newcompanies';

const CompanyTariff = () => {
  const params = useParams();
  const { data: companyDetails } = useReadNCompany({ id: params?.id ?? '' });
  const { data: transferRates } = useGetCompanyRates({
    companyName: companyDetails?.name ?? '',
  });

  const { data: transferTariffs } = useGetCompanyTariff({
    companyName: companyDetails?.name ?? '',
  });
  const [activeTab, setActiveTab] = useState(0);

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleTabChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid
        item
        xs={2}
        sm={1}
        style={{ marginLeft: '10px', marginRight: '30px' }}
      >
        <PageTitle
          text="Company Tariff"
          primaryAction={
            <Button variant="contained" color="primary" onClick={handlePrint}>
              Print
            </Button>
          }
        />
      </Grid>

      <Grid item xs={12} style={{ marginLeft: '10px', marginRight: '30px' }}>
        <Card style={{ padding: '20px' }}>
          <Typography variant="h6">
            Company Name: {companyDetails?.name}
          </Typography>
          <Typography variant="body1">
            More Info: {companyDetails?.info}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="company tariff tabs"
          style={{ marginLeft: '30px' }}
        >
          <Tab label="Tariff" />
          <Tab label="Rates" />
        </Tabs>
        <Box
          sx={{
            padding: 2,
            width: '100%',
            maxWidth: '1410px',
            boxSizing: 'border-box',
          }}
        >
          {activeTab === 0 && <CompanyTransferTariff />}
          {activeTab === 1 && <CompanyTransferRates />}
        </Box>

        {/* Hidden section to include both components for printing */}
        <div style={{ display: 'none' }}>
          <div ref={printRef} style={{ margin: '10px' }}>
            <Typography variant="h4" style={{ marginTop: '20px' }}>
              Tariff
            </Typography>
            <hr />
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Typography variant="h6">
                Company Name :
                <span style={{ fontSize: '15px' }}>
                  {' '}
                  {companyDetails?.name}
                </span>
              </Typography>
              <Typography variant="h6">
                More Info :
                <span style={{ fontSize: '15px' }}>
                  {' '}
                  {companyDetails?.info}
                </span>
              </Typography>
            </div>
            <Typography variant="h6">Transfer Tariffs</Typography>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <thead>
                <tr style={{ borderBottom: '1px solid #000' }}>
                  <th className="table-cell">Transfer Type</th>
                  <th className="table-cell">Vehicle Type</th>
                  <th className="table-cell">Rate</th>
                </tr>
              </thead>
              <tbody>
                {transferTariffs &&
                  transferTariffs.map((tariff: any, index: number) => (
                    <tr key={index} style={{ borderBottom: '1px solid #000' }}>
                      <td className="table-cell">{tariff.ttype}</td>
                      <td className="table-cell">{tariff.vid}</td>
                      <td className="table-cell">{tariff.rate}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Typography variant="h6" style={{ marginTop: '20px' }}>
              Transfer Rates
            </Typography>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <thead>
                <tr style={{ borderBottom: '1px solid #000' }}>
                  <th className="table-cell">Vehicle Type</th>
                  <th className="table-cell">Minimum</th>
                  <th className="table-cell">Extra KM</th>
                  <th className="table-cell">Extra Hr</th>
                  <th className="table-cell">Early</th>
                  <th className="table-cell">Early Morning</th>
                  <th className="table-cell">Late</th>
                  <th className="table-cell">Overnight</th>
                </tr>
              </thead>
              <tbody>
                {transferRates &&
                  transferRates.map((rate: any, index: number) => (
                    <tr key={index} style={{ borderBottom: '1px solid #000' }}>
                      <td className="table-cell">{rate.vid}</td>
                      <td className="table-cell">{rate.mintype}</td>
                      <td className="table-cell">{rate.xkm}</td>
                      <td className="table-cell">{rate.xhr}</td>
                      <td className="table-cell">{rate.early}</td>
                      <td className="table-cell">{rate.early_morning}</td>
                      <td className="table-cell">{rate.late}</td>
                      <td className="table-cell">{rate.onite}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CompanyTariff;
