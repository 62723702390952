import { useFormContext } from 'react-hook-form';

import { Card, Grid, TextField, Typography } from '@mui/material';

function AddSubAgent() {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Add New Sub Agent
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="subagent-name"
                variant="outlined"
                label="SubAgent Name"
                {...register('name')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="more-info"
                variant="outlined"
                label="More Info"
                {...register('info')}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default AddSubAgent;
