import { ReactNode } from 'react';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export interface IMenuItem {
  title: string;
  link?: string;
  onClick?: () => void;
  icon?: ReactNode;
  items?: IMenuItem[];
}

export const menu: IMenuItem[] = [
  {
    title: 'Home',
    link: '/',
    icon: <DashboardOutlinedIcon />,
  },
  {
    title: 'Contacts',
    link: '/contacts',
    icon: <GroupOutlinedIcon />,
  },
  {
    title: 'Hire Chart',
    icon: <CalendarMonthIcon />,

    items: [
      {
        title: 'New Hire',
        link: '/hirechart',
      },
      {
        title: 'Edit Hire',
        link: '/editHire',
      },
      {
        title: 'PDC Hire',
        link: '/pdcHire',
      },
    ],
  },
  {
    title: 'Bill',
    link: '/newBill',
    icon: <ReceiptLongIcon />,
    items: [
      {
        title: 'Pending Bills',
        link: '/pendingBills',
      },
      {
        title: 'Bill Register',
        link: '/billRegister',
      },
      {
        title: 'New Bill',
        link: '/newBill',
      },
      // {
      //   title: 'Direct Bill',
      //   link: '/directBill',
      // },
    ],
  },
  {
    title: 'Proforma',
    link: '/proforma',
    icon: <DescriptionOutlinedIcon />,
  },
  {
    title: 'Contracts',
    link: '/contracts',
    icon: <HistoryEduOutlinedIcon />,
  },
  {
    title: 'Reports',
    link: '/unbilledHires',
    icon: <AssessmentOutlinedIcon />,
    items: [
      {
        title: 'Unbilled Hires',
        link: '/unbilledHires',
      },
      {
        title: 'Vehicle Suppliers',
        link: '/vehicleSuppliers',
      },
      {
        title: 'Vehicle Numbers',
        link: '/VehicleNumbersReport',
      },
      {
        title: 'Vehicle Drivers',
        link: '/VehicleDriversReport',
      },
      {
        title: 'Breakdown Incidence',
        link: '/breakdownIncidence',
      },
    ],
  },
  {
    title: 'Control Panel',
    link: 'controlpanel',
    icon: <SettingsOutlinedIcon />,
  },
];
