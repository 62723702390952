import { useFormContext } from 'react-hook-form';

import { Card, Grid, TextField, Typography } from '@mui/material';

function AddOwnerCode() {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Add Owner Code
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="ocode"
                variant="outlined"
                label="Owner Code"
                {...register('code')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="more-info"
                variant="outlined"
                label="More Info"
                {...register('info')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="mobile"
                variant="outlined"
                label="Mobile"
                {...register('mobile')}
                fullWidth
                margin="normal"
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                variant="outlined"
                label="Phone"
                {...register('phone')}
                fullWidth
                margin="normal"
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default AddOwnerCode;
