import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import {
  IGeneralRates,
  IGeneralTariff,
  IReadGeneralRates,
  IReadGeneralTariff,
} from '../../types/controlpanel/generaltariff';

// READ ALL
const fetchGeneralTariffs = async (): Promise<IGeneralTariff[]> => {
  const { data } = await apiClient.get(`/general-tariff`);
  return data;
};

export const useReadGeneralTariffs = () =>
  useQuery<IGeneralTariff[], Error>({
    queryKey: ['general-tariff', 'list'],
    queryFn: fetchGeneralTariffs,
  });

// READ ONE
const fetchGeneralTariff = async (
  params: IReadGeneralTariff
): Promise<IGeneralTariff> => {
  const { data } = await apiClient.get(`/general-tariff/${params.id}`);
  return data;
};

export const useReadGeneralTariff = (params: IReadGeneralTariff) =>
  useQuery<IGeneralTariff, Error>({
    queryKey: ['general-tariff', 'details', params.id],
    queryFn: () => fetchGeneralTariff(params),
  });

// usemutation
export const useAddGeneralTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/general-tariff'],
    mutationFn: (data: any) => apiClient.post(`/general-tariff`, data),
  });
  return { mutate };
};

export const useEditGeneralTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/general-tariff'],
    mutationFn: (data: any) =>
      apiClient.put(`/general-tariff/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteGeneralTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/general-tariff'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/general-tariff/${id}`),
  });
  return { mutate };
};

// READ ALL
const fetchGeneralRates = async (): Promise<IGeneralRates[]> => {
  const { data } = await apiClient.get(`/general-rates`);
  return data;
};

export const useReadGeneralRates = () =>
  useQuery<IGeneralRates[], Error>({
    queryKey: ['general-rates', 'list'],
    queryFn: fetchGeneralRates,
  });

// READ ONE
const fetchGeneralRate = async (
  params: IReadGeneralRates
): Promise<IGeneralRates> => {
  const { data } = await apiClient.get(`/general-rates/${params.id}`);
  return data;
};

export const useReadGeneralRate = (params: IReadGeneralRates) =>
  useQuery<IGeneralRates, Error>({
    queryKey: ['general-rates', 'details', params.id],
    queryFn: () => fetchGeneralRate(params),
  });

// usemutation
export const useAddGeneralRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/general-rates'],
    mutationFn: (data: any) => apiClient.post(`/general-rates`, data),
  });
  return { mutate };
};

export const useEditGeneralRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/general-rates'],
    mutationFn: (data: any) =>
      apiClient.put(`/general-rates/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteGeneralRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/general-rates'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/general-rates/${id}`),
  });
  return { mutate };
};
