import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IBillDates, IReadBillDates } from '../../types/controlpanel/billdates';

// READ ALL
const fetchBillDates = async (): Promise<IBillDates[]> => {
  const { data } = await apiClient.get(`/bill-date`);
  return data;
};

export const useReadBillDates = () =>
  useQuery<IBillDates[], Error>({
    queryKey: ['billdates', 'list'],
    queryFn: fetchBillDates,
  });

// READ ONE
const fetchBillDate = async (params: IReadBillDates): Promise<IBillDates> => {
  const { data } = await apiClient.get(`/bill-date/${params.id}`);
  return data;
};

export const useReadBillDate = (params: IReadBillDates) =>
  useQuery<IBillDates, Error>({
    queryKey: ['bills', 'details', params.id],
    queryFn: () => fetchBillDate(params),
  });

// useMutation
export const useAddBillDates = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-billdates'],
    mutationFn: (data: any) => apiClient.post('/bill-date', data),
  });
  return {
    mutate,
  };
};

export const useEditBillDates = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-billdates'],
    mutationFn: (data: any) => apiClient.put(`/bill-date/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteBillDates = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-billdates'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/bill-date/${id}`),
  });
  return { mutate };
};
