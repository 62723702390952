import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import {
  INCompany,
  IReadNCompany,
} from '../../types/controlpanel/newcompanies';

// READ ALL
const fetchNCompanys = async (): Promise<INCompany[]> => {
  const { data } = await apiClient.get('/company');
  return data;
};

export const useReadNCompanys = () =>
  useQuery<INCompany[], Error>({
    queryKey: ['company', 'list'],
    queryFn: fetchNCompanys,
  });

// READ ALL
const fetchNCompanys2 = async (): Promise<INCompany[]> => {
  const { data } = await apiClient.get('/company2');
  return data;
};

export const useReadNCompanys2 = () =>
  useQuery<INCompany[], Error>({
    queryKey: ['company2', 'list'],
    queryFn: fetchNCompanys2,
  });

// READ ONE
const fetchNCompany = async (params: IReadNCompany): Promise<INCompany> => {
  const { data } = await apiClient.get(`/company/${params.id}`);
  return data;
};

export const useReadNCompany = (params: IReadNCompany) =>
  useQuery<INCompany, Error>({
    queryKey: ['company', 'details', params.id],
    queryFn: () => fetchNCompany(params),
  });

// CREATE
export const useAddNCompany = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-company'],
    mutationFn: (data: any) => apiClient.post('/company', data),
  });
  return {
    mutate,
  };
};

export const useAddNCompany2 = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-company2'],
    mutationFn: (data: any) => apiClient.post('/company2', data),
  });
  return {
    mutate,
  };
};

// UPDATE
export const useEditNCompany = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-company'],
    mutationFn: (data: any) => apiClient.put(`/company/${data.id}`, data),
  });
  return { mutate };
};
export const useEditNCompany2 = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-company2'],
    mutationFn: (data: any) => apiClient.put(`/company2/${data.id}`, data),
  });
  return { mutate };
};

// DELETE
export const useDeleteNCompany = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-company'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/company/${id}`),
  });
  return { mutate };
};

// CompanyTariff

// useMutation

export const useGetCompanyTariff = ({
  companyName,
}: {
  companyName: string;
}) => {
  const { data, refetch } = useQuery({
    queryKey: ['get-company-tariff'],
    queryFn: () =>
      apiClient.post('/subagent/tariff', {
        cid: companyName,
      }),
    enabled: !!companyName,
  });
  return {
    data: data?.data ?? [],
    refetch,
  };
};

export const useTariffByTransferType = (transferType: any) =>
  useQuery({
    queryKey: ['get-tariff-by-transfer-type', transferType],
    queryFn: async () => {
      if (!transferType) return [];
      const response = await apiClient.post('/subagent/tariff', {
        ttype: transferType,
      });
      return response.data.data;
    },
    enabled: !!transferType,
  });

export const useAddCompanyTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/company/tariff'],
    mutationFn: (data: any) => apiClient.post(`/tariff`, data),
  });
  return { mutate };
};

export const useEditCompanyTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/company/tariff'],
    mutationFn: (data: any) => apiClient.put(`/tariff/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteCompanyTariff = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/company/tariff'],
    mutationFn: (data: any) => apiClient.post(`/tariff/delete-row`, data),
  });
  return { mutate };
};

// CompanyRates

// useMutation
export const useGetCompanyRates = ({
  companyName,
}: {
  companyName: string;
}) => {
  const { data, refetch } = useQuery({
    queryKey: ['get-company-rates'],
    queryFn: () =>
      apiClient.post('/subagent/rates', {
        cid: companyName,
      }),
    enabled: !!companyName,
  });
  return {
    data: data?.data ?? [],
    refetch,
  };
};

export const useAddCompanyRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-/company/rates'],
    mutationFn: (data: any) => apiClient.post(`/rates`, data),
  });
  return { mutate };
};

export const useEditCompanyRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-/company/rates'],
    mutationFn: (data: any) => apiClient.put(`/rates/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteCompanyRates = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-/company/rates'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/rates/${id}`),
  });
  return { mutate };
};
