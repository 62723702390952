import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddNewCompanies from '@components/ControlPanel/NewCompanies/AddNewCompanies';
// import EditCompanyTariff from '@components/ControlPanel/NewCompanies/CompanyTariff/EditCompanyTariff';
import EditNewCompanies from '@components/ControlPanel/NewCompanies/EditNewCompanies';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddNCompany,
  useAddNCompany2,
  useDeleteNCompany,
  useEditNCompany,
  useEditNCompany2,
  useReadNCompanys,
  useReadNCompanys2,
} from '../../../hooks/controlpanel/newcompanies';
import { INCompany } from '../../../types/controlpanel/newcompanies';

const Companies = () => {
  const { data: ncompanys, isLoading, refetch } = useReadNCompanys();
  const { data: ncompanys2 } = useReadNCompanys2();

  const [localncompany, setLocalNcompany] = useState<INCompany[]>([]);
  const [ncompany, setNCompany] = useState<INCompany | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  // const [editTariffDialogOpen, setEditTariffDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteNcompanyMutate } = useDeleteNCompany();
  const { mutate: addNcompanyMutate } = useAddNCompany();
  const { mutate: addNcompany2Mutate } = useAddNCompany2();
  const { mutate: editNcompanyMutate } = useEditNCompany();
  const { mutate: editNcompany2Mutate } = useEditNCompany2();

  console.log(ncompanys);
  useEffect(() => {
    if (ncompanys) {
      setLocalNcompany(ncompanys);
    }
  }, [ncompanys]);

  const handleEdit = (rowData: any) => {
    setNCompany(rowData);
    const company2 = ncompanys2?.find(c => c.name === rowData.name);
    setDefaultvalue({
      name: rowData.name,
      cname: rowData.cname,
      info: rowData.info,
      gstno: rowData.gstno,
      panno: rowData.panno,
      address: rowData.address,
      mintype: rowData.mintype,
      gst: rowData.gst,
      gst2: company2?.gst,
      edate: rowData.edate,
      edate1: rowData.edate1,
      gstdate1: rowData.gstdate1,
      gstdate: rowData.gstdate,
      gstdate2: company2?.gstdate,
      gstdate3: company2?.gstdate1,
      gsttype: rowData.gsttype,
      status: rowData.status,
    });
    setEditDialogOpen(true);
  };

  // const handleEditTariff = (rowData: any) => {
  //   setNCompany(rowData);
  //   setDefaultvalue({
  //     tariff: rowData?.tariff,
  //   });
  //   setEditTariffDialogOpen(true);
  //   enqueueSnackbar('Edit Tariff Modal', { variant: 'info' });
  // };

  const handleDelete = (rowData: any) => {
    setNCompany(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (ncompany && action && action?.action) {
      editNcompanyMutate(
        { ...action?.formData, id: ncompany.id },
        {
          onSuccess: () => {
            editNcompany2Mutate(
              { ...action?.formData, id: ncompany.id },
              {
                onSuccess: () => {
                  refetch();
                  setEditDialogOpen(false);
                  enqueueSnackbar('Content Updated', { variant: 'info' });
                },
                onError: () => {
                  enqueueSnackbar('Failed to Update Content', {
                    variant: 'error',
                  });
                },
              }
            );
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  // const handleCloseEditTariffDialog = (action: any) => {
  //   if (ncompany && action && action?.action) {
  //     editNcompanyMutate(
  //       { ...action?.formData, id: ncompany.id },
  //       {
  //         onSuccess: data => {
  //           const index = localncompany.findIndex(d => d.id === ncompany.id);
  //           if (index >= 0) {
  //             const arr = [...localncompany];
  //             arr[index] = JSON.parse(data?.config?.data);
  //             setLocalNcompany(arr);
  //           }
  //           setEditTariffDialogOpen(false);
  //         },
  //         onError: error => {},
  //       }
  //     );
  //   } else {
  //     setEditTariffDialogOpen(false);
  //   }
  // };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (ncompany) {
      deleteNcompanyMutate(
        { id: +ncompany.id },
        {
          onSuccess: () => {
            setLocalNcompany(prevNcompany =>
              prevNcompany.filter(c => c.id !== ncompany.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addNcompanyMutate(action?.formData, {
        onSuccess: () => {
          addNcompany2Mutate(action?.formData, {
            onSuccess: () => {
              refetch();
              setAddDialogOpen(false);
            },
            onError: () => {
              enqueueSnackbar('Failed to Add Content', { variant: 'error' });
            },
          });
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<INCompany>[] = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Traffic Set',
      field: 'trafficset',
    },
    {
      title: 'Current Tariff',
      render: rowData => (
        <Link to={`/companyTariff/${rowData.id}`}>Tariff</Link>
      ),
    },

    {
      title: 'Eff. from date',
      field: 'edate',
    },
    {
      title: 'Eff. to date',
      field: 'edate1',
    },
    {
      title: 'New Traffic Set',
      field: 'newtrafficset',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Companies"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add New Company
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localncompany || []}
              isLoading={isLoading}
              options={{
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
                draggable: false,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddNewCompanies}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditNewCompanies}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      {/* <EditDialog
        open={editTariffDialogOpen}
        handleClose={handleCloseEditTariffDialog}
        ContentComponent={EditCompanyTariff}
        billMade={false}
        defaultvalue={defaultvalue}
      /> */}
      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Companies;
