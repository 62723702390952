import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Card, Grid, MenuItem, TextField, Typography } from '@mui/material';

const AddUsers: React.FC = () => {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5">Add New User</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="First Name"
                {...register('firstname')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Last Name"
                {...register('lastname')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Email"
                {...register('useremail')}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label="Password"
                type="password"
                {...register('password')}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                select
                label="User Level"
                defaultValue="2"
                {...register('userlevel')}
                fullWidth
                margin="normal"
              >
                {/* <MenuItem value="0">User</MenuItem>
                <MenuItem value="1">Power User</MenuItem> */}
                <MenuItem value="2">Administrator</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default AddUsers;
