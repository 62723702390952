/* eslint-disable no-useless-escape */
import { useEffect, useRef, useState } from 'react';

import parse from 'html-react-parser';
import { useReactToPrint } from 'react-to-print';
import { ToWords } from 'to-words';

import { Button, Grid, Paper } from '@mui/material';

// import DeleteDialog from '@components/Dialog/DeleteDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useReadCompanies } from '../../hooks/company';

// import { useSnackbar } from 'notistack';

const ContractView = () => {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const { enqueueSnackbar } = useSnackbar();

  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: '',
        },
      },
    },
  });

  const [rawHTML, setRawHTML] = useState('');
  const [showHTML, setShowHTML] = useState(false);
  const [companyData, setCompanyData] = useState<any>(null);

  // const { data } = useReadHireChartMulti({ id } as any);
  const data = JSON.parse(localStorage.getItem('contract') as string);
  const contractData = data.contract;
  const cpartList = JSON.parse(localStorage.getItem('cpartList') as string);
  const { data: companies } = useReadCompanies();

  // const { data: billRows } = useReadBills();

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (
      contractData &&
      cpartList &&
      cpartList.length &&
      companies &&
      companies.length
    ) {
      const firstRow = contractData;
      const c = companies.find(cp => cp.name === firstRow.company);
      setCompanyData(c);
      if (!c) return;
      const gstValue = Number(companyData?.gst) || 0;
      const gstType = Number(companyData?.gstType);
      let rawHTMLString = ``;

      if (gstValue === 5) {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Transport for passengers under air conditioned contract carriage other than motor cab SAC - 996419 / 996413.</strong></p>`;
      } else if (gstValue === 18 || gstValue === 12) {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of road vehicles including buses / coaches, with or without operator SAC - 996601.</strong></p>`;
      } else if (gstValue === 0 && gstType !== 3) {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of transport vehicles, with or without operators SAC – 996601. (Under Sr. No. 10(i) of Noti. 11/2017 & 31/2017)  </strong></p>`;
      } else if (gstValue === 0 && gstType !== 4) {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of transport vehicles, with or without operators SAC – 996601. (Under Sr. No. 10(i) of Noti. 11/2017 & 31/2017)  </strong></p>`;
      } else if (gstType === 3) {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Transport of passengers under contract carriage other than motor cab, exempt services provided to educational institution SAC - 996419 / 996413.</strong></p>`;
      }

      rawHTMLString += `
                  <p><strong>Date: </strong>${formatDate(firstRow?.date) || ''}
                  <p><strong>To: </strong>${c?.name || ''}</p>                
                  <p><strong>Address: </strong>${c?.address || ''}</p>
                  <p><strong>GST Number: </strong>${c?.gstno || ''}</p>
                  <p><strong>PAN Number: </strong>${c?.panno || ''}</p>
                  <p><strong>Bill No: </strong>${firstRow?.fullBillNo || ''}</p>
                  <table id="table-h" border="1" cellspacing="0" cellpadding="3" width="100%" style="font-size:11px;">
                  <tr valign="top">
                  <td colspan="3"><strong>Vehicle</strong></td>
                  <td><strong>Particulars</strong></td>
                  <td><strong>KMS/No. of nights</strong></td>
                  <td colspan="2"><strong>Rate</strong></td>
                  <td><strong>Amount</strong></td>
                  </tr>
                  `;

      let total = 0;
      let discount = 0;
      cpartList?.map((row: any) => {
        rawHTMLString += `<tr valign=\"top\"><td colspan="3" align=\"center\">${row?.vtype}</td><td align=\"center\">${row?.particular || ''}</td><td>${row?.kms || 0}</td><td colspan="2" align=\"center\">${row?.rate || 0}</td><td align=\"right\">${row?.amount || 0}</td>"`;
        total += Number(row?.amount || 0);
        discount = Number(row?.discount || 0);

        return null;
      });

      const netTaxable = total - discount;
      const gstAmount = netTaxable * (gstValue / 100);
      const grandTotal = netTaxable + gstAmount;

      // eslint-disable-next-line
      rawHTMLString += `
                  <tr>
                    <td colspan="5"></td>
                    <td colspan="2"><strong>Total (Rs.)</strong></td>
                    <td  align="right">${total}</td>
                  </tr>
                  <tr>
                  <tr>  
                    <td colspan="5"></td>  
                    <td colspan="2"><strong>Less (Rs.)</strong></td>
                    <td align="right">${discount}</td>
                  </tr>
                  <tr>
                  <td colspan="5"><strong>${toWords.convert(grandTotal)}</strong></td>
                  <td colspan="2"><strong>G. Total (Rs.)</strong></td>
                  <td align="right"><strong>&nbsp;${grandTotal}&nbsp;</strong></td>
                  </tr>
                  </table>`;

      console.log('@@@', rawHTMLString);
      setRawHTML(rawHTMLString);
      setShowHTML(true);
    }
    // eslint-disable-next-line
  }, [companies]);

  useEffect(() => {
    const a = document.getElementById('table-h');
    const b = a?.previousSibling;
    b?.remove();
  }, [rawHTML]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Contract" />
      </Grid>
      <Grid item xs={2} sm={1} alignSelf="flex-end" py={2}>
        <Button
          style={{ paddingLeft: '58px', paddingRight: '58px' }}
          variant="contained"
          color="primary"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Grid>
      {/* <Grid item xs={2} sm={1} alignSelf="flex-end" py={2}>
        {companyData && (
          <CSVLink filename={`bill_${companyData?.name}.csv`} data={csvData}>
            <Button variant="contained" color="primary">
              Download CSV
            </Button>
          </CSVLink>
        )}
      </Grid> */}
      <Grid ref={printRef} item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12} mx={4} my={4}>
            {/* eslint-disable-next-line */}
            {showHTML && parse(rawHTML)}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ContractView;
