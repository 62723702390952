import React, { useState } from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';

import GeneralTransferRates from '@components/ControlPanel/General/GeneralRates/GeneralTransferRates';
import GeneralTransferTariff from '@components/ControlPanel/General/GeneralTariff/GeneralTransferTariff';
import PageTitle from '@components/PageTitle';

const GeneralTariff = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid
        item
        xs={2}
        sm={1}
        style={{ marginLeft: '30px', marginTop: '10px' }}
      >
        <PageTitle text="General Tariff" />
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="general tariff tabs"
          style={{ marginLeft: '30px' }}
        >
          <Tab label="Tariff" />
          <Tab label="Rates" />
        </Tabs>
        <Box sx={{ padding: 2 }}>
          {activeTab === 0 && <GeneralTransferTariff />}
          {activeTab === 1 && <GeneralTransferRates />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GeneralTariff;
