import {
  Button,
  Card,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';

function EditProforma() {
  const contact = {
    id: 1,
    cname: 'Example Company',
    address: '123 Main St, City, Country',
    phone: '+1234567890',
    cpo_fname: 'John',
    cpo_lname: 'Doe',
    cpo_phone1: '+1234567890',
    cpo_phone2: '+0987654321',
    cpa_fname: 'Jane',
    cpa_lname: 'Smith',
    cpa_phone1: '+1357924680',
    cpa_phone2: '+9876543210',
  };

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ padding: '20px' }}>
          <Typography style={{ marginBottom: '25px', fontSize: '25px' }}>
            Edit Proforma
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="cname"
                label="Company Name"
                defaultValue={contact.cname}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="phone"
                label="Phone"
                defaultValue={contact.phone}
                fullWidth
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={6}>
              {/* <Typography>Address:</Typography> */}
              <TextareaAutosize
                id="address"
                defaultValue={contact.address}
                minRows={6}
                maxRows={8}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            className="menubuttons1"
            style={{ marginTop: '10px' }}
          >
            Update
          </Button>
        </Card>
      </div>
      <br />
    </div>
  );
}

export default EditProforma;
