import { useFormContext } from 'react-hook-form';

import { Card, Grid, TextField, Typography } from '@mui/material';

function EditSubAgentRates() {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit Sub Agent Rates
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="vehicle-type"
                variant="outlined"
                label="Vehicle Type"
                {...register('vid')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="minimum"
                variant="outlined"
                label="Minimum"
                {...register('mintype')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="initial"
                variant="outlined"
                label="Initial"
                {...register('initial')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extra-km"
                variant="outlined"
                label="Extra Km"
                {...register('xkm')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extra-hour"
                variant="outlined"
                label="Extra Hour"
                {...register('xhr')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="early"
                variant="outlined"
                label="Early"
                {...register('early')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="early-morning"
                variant="outlined"
                label="Early Morning"
                {...register('early_morning')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="late"
                variant="outlined"
                label="Late"
                {...register('late')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="overnite"
                variant="outlined"
                label="Overnight"
                {...register('onite')}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default EditSubAgentRates;
