import { useMutation } from '@tanstack/react-query';

import apiClient from '@constants/axios';

export const useAddAdditional = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-additional'],
    mutationFn: (data: any) => apiClient.post(`/additional`, data),
  });
  return {
    mutate,
  };
};
