import { useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

const fetchExtras = async () => {
  const { data } = await apiClient.get(`/extra`);
  return data;
};

export const useReadExtras = () =>
  useQuery<any[], Error>({
    queryKey: ['extras', 'list'],
    queryFn: fetchExtras,
  });
