import { useFormContext } from 'react-hook-form';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const positions = ['Driver', 'Cleaner', 'Mechanic'];

function AddDriver() {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Add Driver/Cleaner
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                {...register('name')}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Position</InputLabel>
                <Select {...register('role')} label="Position">
                  {positions.map(position => (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="rphone"
                label="Phone (Residence)"
                variant="outlined"
                fullWidth
                {...register('rphone')}
                margin="normal"
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="mphone"
                label="Phone (Mobile)"
                variant="outlined"
                fullWidth
                {...register('mphone')}
                margin="normal"
                inputProps={{
                  type: 'tel',
                  pattern: '[0-9]*',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="jdate"
                label="Date of Joining"
                type="date"
                variant="outlined"
                fullWidth
                {...register('jdate')}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                {...register('address')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="remark"
                label="Remark"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                {...register('remark')}
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default AddDriver;
