import { useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

const fetchCompanies = async () => {
  const { data } = await apiClient.get(`/company`);
  return data;
};

export const useReadCompanies = () =>
  useQuery<any[], Error>({
    queryKey: ['companies', 'list'],
    queryFn: fetchCompanies,
  });

const fetchCompanies2 = async () => {
  const { data } = await apiClient.get(`/company2`);
  return data;
};

export const useReadCompanies2 = () =>
  useQuery<any[], Error>({
    queryKey: ['companies2', 'list'],
    queryFn: fetchCompanies2,
  });
