import { ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';

interface IPageTitle {
  text: string;
  primaryAction?: ReactNode;
}

const PageTitle = ({ text, primaryAction }: IPageTitle) => (
  <Grid container justifyContent="space-between">
    <Grid item>
      <Typography variant="h4">{text}</Typography>
    </Grid>
    <Grid item>{primaryAction}</Grid>
  </Grid>
);

export default PageTitle;
