import { useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

const fetchTransfers = async () => {
  const { data } = await apiClient.get(`/transfer`);
  return data;
};

export const useReadTransfers = () =>
  useQuery<any[], Error>({
    queryKey: ['transfers', 'list'],
    queryFn: fetchTransfers,
  });
