import { useFormContext } from 'react-hook-form';

import { Card, Grid, TextField, Typography } from '@mui/material';

function EditSector() {
  const { register } = useFormContext();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5">Edit Sector</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sector-name"
                variant="outlined"
                label="Sector Name"
                {...register('sector_name')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sector-initial"
                variant="outlined"
                label="Sector Initial"
                {...register('sector_initial')}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default EditSector;
