import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddVehicleNumber from '@components/ControlPanel/VehicleNumber/AddVehicleNumber';
import EditVehicleNumber from '@components/ControlPanel/VehicleNumber/EditVehicleNumber';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddVehicleNumber,
  useDeleteVehicleNumber,
  useEditVehicleNumber,
  useReadVehicleNumbers,
} from '../../../hooks/controlpanel/vehiclenumber';
import { IVehicleNumber } from '../../../types/controlpanel/vehiclenumber';

const VehicleNumbers = () => {
  const { data: vnos, isLoading, refetch } = useReadVehicleNumbers();

  const [localvno, setLocalVno] = useState<IVehicleNumber[]>([]);
  const [vno, setVno] = useState<IVehicleNumber | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteVehiclenoMutate } = useDeleteVehicleNumber();
  const { mutate: addVehiclenoMutate } = useAddVehicleNumber();
  const { mutate: editVehiclenoMutate } = useEditVehicleNumber();

  useEffect(() => {
    if (vnos) {
      setLocalVno(vnos);
    }
  }, [vnos]);

  const handleEdit = (rowData: any) => {
    setVno(rowData);
    setDefaultvalue({
      num: rowData?.number,
      info: rowData?.info,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setVno(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (vno && action && action?.action) {
      editVehiclenoMutate(
        { ...action?.formData, id: vno.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (vno) {
      deleteVehiclenoMutate(
        { id: +vno.id },
        {
          onSuccess: () => {
            setLocalVno(prevVno => prevVno.filter(c => c.id !== vno.id));
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addVehiclenoMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IVehicleNumber>[] = [
    {
      title: 'Vehicle No.',
      field: 'number',
    },
    {
      title: 'Info',
      field: 'info',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Vehicle Number"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Vehicle Number
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localvno || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Vno.',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Vno.',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddVehicleNumber}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditVehicleNumber}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default VehicleNumbers;
