import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import {
  IReadVehicleNumber,
  IVehicleNumber,
} from '../../types/controlpanel/vehiclenumber';

// READ ALL
const fetchVehicleNumbers = async (): Promise<IVehicleNumber[]> => {
  const { data } = await apiClient.get(`/vehicle-no`);
  return data;
};

export const useReadVehicleNumbers = () =>
  useQuery<IVehicleNumber[], Error>({
    queryKey: ['vehiclenumber', 'list'],
    queryFn: fetchVehicleNumbers,
  });

// READ ONE
const fetchVehicleNumber = async (
  params: IReadVehicleNumber
): Promise<IVehicleNumber> => {
  const { data } = await apiClient.get(`/vehicle-no/${params.id}`);
  return data;
};

export const useReadVehicleNumber = (params: IReadVehicleNumber) =>
  useQuery<IVehicleNumber, Error>({
    queryKey: ['vehiclenumber', 'details', params.id],
    queryFn: () => fetchVehicleNumber(params),
  });

// useMutation
export const useAddVehicleNumber = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-vehiclenumber'],
    mutationFn: (data: any) => apiClient.post('/vehicle-no', data),
  });
  return {
    mutate,
  };
};

export const useEditVehicleNumber = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-vehiclenumber'],
    mutationFn: (data: any) => apiClient.put(`/vehicle-no/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteVehicleNumber = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-vehiclenumber'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/vehicle-no/${id}`),
  });
  return { mutate };
};
