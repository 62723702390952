import { useFormContext } from 'react-hook-form';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useReadVehicleTypes } from '../../../../hooks/controlpanel/vehicletype';

function AddCompanyRates() {
  const { register } = useFormContext();
  const { data: vtypes } = useReadVehicleTypes();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Add New Company Rates
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="tid-label">Vehicle Type</InputLabel>
                <Select label="Transfer Type" {...register('vid')}>
                  {vtypes?.map(types => (
                    <MenuItem key={types.id} value={types.type}>
                      {types?.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="minimum"
                variant="outlined"
                label="Minimum"
                {...register('mintype')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="initial"
                variant="outlined"
                label="Initial"
                {...register('initial')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extra-km"
                variant="outlined"
                label="Extra Km"
                {...register('xkm')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extra-hour"
                variant="outlined"
                label="Extra Hour"
                {...register('xhr')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="early"
                variant="outlined"
                label="Early"
                {...register('early')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="early-morning"
                variant="outlined"
                label="Early Morning"
                {...register('early_morning')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="late"
                variant="outlined"
                label="Late"
                {...register('late')}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="overnite"
                variant="outlined"
                label="Overnight"
                {...register('onite')}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default AddCompanyRates;
