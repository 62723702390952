import { Controller, useFormContext } from 'react-hook-form';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useReadTransfers } from '../../../../hooks/controlpanel/transfer';
import { useReadVehicleTypes } from '../../../../hooks/controlpanel/vehicletype';

function EditGeneralTariff() {
  const { register } = useFormContext();
  const { data: transfers } = useReadTransfers();
  const { data: vtypes } = useReadVehicleTypes();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit General Tariff
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Vehicle Type</InputLabel>
                <Controller
                  name="vid"
                  render={({ field }) => (
                    <Select {...field} label="Vehicle Type">
                      {vtypes?.map(vtype => (
                        <MenuItem key={vtype.id} value={vtype.type}>
                          {vtype?.type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Transfers</InputLabel>
                <Controller
                  name="tid"
                  render={({ field }) => (
                    <Select {...field} label="Transfer Type">
                      {transfers?.map(transfer => (
                        <MenuItem key={transfer.id} value={transfer.id}>
                          {transfer?.type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="rate"
                variant="outlined"
                type="number"
                label="Rate"
                {...register('rate')}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default EditGeneralTariff;
