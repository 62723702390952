import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { useReadHireChart } from '../../hooks/hirechart';
import { IHireChart, IReadHireChart } from '../../types/hirechart';

interface HireChartDetailProps {
  data?: IHireChart | null;
}

const HireChartDetails = ({ data }: HireChartDetailProps) => {
  const id = data?.id;
  const {
    data: hirechart,
    isLoading,
    isError,
  } = useReadHireChart({ id } as IReadHireChart);

  const fields = [
    { label: 'Date', value: hirechart?.date },
    { label: 'Company', value: hirechart?.company },
    { label: 'Veh Type', value: hirechart?.vtype },
    { label: 'Own Code', value: hirechart?.ocode },
    { label: 'Veh No.', value: hirechart?.vno },
    { label: 'Rep Veh', value: hirechart?.repveh },
    { label: 'Rep Own Code', value: hirechart?.rvocode },
    { label: 'Particulars', value: hirechart?.particulars_type },
    { label: 'Hire Type', value: hirechart?.hiretype },
    { label: 'Sub Agent', value: hirechart?.subagent },
    { label: 'Client', value: hirechart?.client },
    { label: 'Toll', value: hirechart?.toll },
    { label: 'Bill No.', value: hirechart?.billno },
  ];

  if (isError) {
    return <div>Error fetching Hire Chart</div>;
  }

  return (
    <div className="content">
      <div className="content-inner">
        <Box style={{ padding: '10px', margin: '10px' }}>
          <Typography variant="h5">Hire Chart Details</Typography>
        </Box>

        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <strong>{field.label}:</strong>
                    </TableCell>
                    <TableCell>{field.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default HireChartDetails;
