import { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import apiClient from '@constants/axios';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogin = async () => {
    if (!username || !password) {
      enqueueSnackbar('Please enter both username and password', {
        variant: 'info',
      });
      return;
    }
    try {
      const response = await apiClient.post('/login', { username, password });
      if (response && response.data && response.data.is_logged_in) {
        let currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
          // eslint-disable-next-line no-plusplus
          currentYear--;
        }
        localStorage.setItem('year', String(currentYear));
        sessionStorage.setItem('isAuthenticated', 'true');
        navigate('/');
      } else {
        enqueueSnackbar('Failed to Login', { variant: 'error' });
      }
    } catch (error: any) {
      console.error('Login Error:', error);
      const errorMessage =
        error?.response?.data?.messages?.error || 'Failed to Login';
      if (errorMessage === 'user does not exist') {
        enqueueSnackbar('Incorrect Username or Password', { variant: 'info' });
      } else {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={e => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;
