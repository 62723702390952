import {
  Box,
  Card,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { useReadProforma } from '../../hooks/proforma';
import { IProforma, IReadProforma } from '../../types/proforma';

interface ProformaDetailProps {
  data?: IProforma | null;
}

const ProformaDetail = ({ data }: ProformaDetailProps) => {
  const id = data?.id;
  const {
    data: proforma,
    isLoading,
    isError,
  } = useReadProforma({ id } as IReadProforma);

  if (isError) {
    return <div>Error fetching Proforma</div>;
  }

  return (
    <div className="content">
      <div className="content-inner">
        <Box style={{ padding: '10px', margin: '10px' }}>
          <Typography variant="h5">Proforma Details</Typography>
        </Box>

        {isLoading ? (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Card style={{ margin: '10px' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Company Name:</strong>
                    </TableCell>
                    <TableCell>{proforma?.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Address:</strong>
                    </TableCell>
                    <TableCell>{proforma?.address.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Phone:</strong>
                    </TableCell>
                    <TableCell>{proforma?.phone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ProformaDetail;
