import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IDrivers, IReadDrivers } from '../../types/controlpanel/drivers';

// READ ALL
const fetchDrivers = async (): Promise<IDrivers[]> => {
  const { data } = await apiClient.get(`/driver`);
  return data;
};

export const useReadDrivers = () =>
  useQuery<IDrivers[], Error>({
    queryKey: ['drivers', 'list'],
    queryFn: fetchDrivers,
  });

// READ ONE
const fetchDriver = async (params: IReadDrivers): Promise<IDrivers> => {
  const { data } = await apiClient.get(`/driver/${params.id}`);
  return data;
};

export const useReadDriver = (params: IReadDrivers) =>
  useQuery<IDrivers, Error>({
    queryKey: ['drivers', 'details', params.id],
    queryFn: () => fetchDriver(params),
  });

// useMutation
export const useAddDrivers = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-drivers'],
    mutationFn: (data: any) => apiClient.post('/driver', data),
  });
  return {
    mutate,
  };
};

export const useEditDrivers = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-drivers'],
    mutationFn: (data: any) => apiClient.put(`/driver/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteDrivers = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-drivers'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/driver/${id}`),
  });
  return { mutate };
};
