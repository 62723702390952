import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useUpdateBillByHid } from '../../hooks/bill';
import { useReadExtras } from '../../hooks/extra';
import {
  useReadHireChartMulti,
  useReadRates,
  useUpdateHireChart,
} from '../../hooks/hirechart';
import {
  useAddPtoll,
  useDeletePtollByHid,
  useReadPtolls,
} from '../../hooks/ptoll';
// import { useReadProforma, useUpdateProforma } from '../../hooks/proforma';
import { useReadTransfers } from '../../hooks/transfer';

const BillRegisterEdit = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [particular, setParticular] = useState('');
  const [premark, setPremark] = useState('');
  const [bmode, setBmode] = useState('1');
  const [date, setDate] = useState('');
  const [company, setCompany] = useState('');
  const [repveh, setRepVeh] = useState('');
  const [owncode, setOwncode] = useState('');
  // setOwncode('');
  const [ac, setAc] = useState('');
  const [vehNo, setVehNo] = useState('');
  const [vehType, setVehType] = useState('');

  const [minType, setMinType] = useState('1');
  const [kms, setKms] = useState('');
  const [extraKms, setExtraKms] = useState('');
  const [totalExtraKms, setTotalExtraKms] = useState('');
  const [extraHrs, setExtraHrs] = useState('');
  const [totalWaiting, setTotalWaiting] = useState('');

  const [rate, setRate] = useState('');
  const [waitChgs, setWaitChgs] = useState('');

  const [early, setEarly] = useState('');
  const [earlyMorning, setEarlyMorning] = useState('');
  const [late, setLate] = useState('');
  const [overnight, setOvernight] = useState('');

  const [earlyCheckbox, setEarlyCheckbox] = useState(false);
  const [earlyMorningCheckbox, setEarlyMorningCheckbox] = useState(false);
  const [lateCheckbox, setLateCheckbox] = useState(false);
  const [niteCheckbox, setNiteCheckbox] = useState(false);

  const [transfer, setTransfer] = useState('');
  // const [transfers, setTransfers] = useState([]);

  const [extraComment, setExtraComment] = useState('');

  const [extrasToll, setExtrasToll] = useState('');

  const [extraCheckbox, setExtraCheckbox] = useState<any>([]);
  const [extrasData, setExtrasData] = useState<any>([]);

  const { data, isLoading, isError } = useReadHireChartMulti({ id } as any);

  const { data: transferData } = useReadTransfers();

  const { data: extraData } = useReadExtras();
  let { data: ptollsData } = useReadPtolls();
  const { data: ratesData } = useReadRates();

  useEffect(() => {
    document.title = 'Bill - nunes';
    if (data) {
      if (data.bill?.mintype === '1' || data.bill?.mintype === '2') {
        setMinType(data.bill?.mintype);
      }
      if (data?.bill?.tid && data?.bill?.tid !== '0') {
        setTransfer(data.bill?.tid);
      } else {
        setTransfer('');
      }
      setKms(data.bill?.kms);
      setExtraKms(data.bill?.xkms);
      setTotalExtraKms(data.bill?.tot1);
      setTotalWaiting(data.bill?.tot2);
      setRate(data.bill?.rate);
      setExtraHrs(data.bill?.xhrs);
      setWaitChgs(data.bill?.wchr);

      setBmode(data.bill?.mode);
      setEarly(data.bill?.early);
      setEarlyMorning(data.bill?.early_morning);
      setLate(data.bill?.late);
      setOvernight(data.bill?.onite);

      setDate(data.hirechart?.date);
      setCompany(data.hirechart?.company);
      setAc(data.hirechart?.ac);
      setRepVeh(data.hirechart?.repveh);
      setOwncode(data.hirechart?.owncode);
      setVehNo(data.hirechart?.vno);
      setVehType(data.hirechart?.vtype);

      setParticular(data.hirechart?.particulars);
      setPremark(data.hirechart?.remark);

      setExtrasToll(data.hirechart?.toll);
      setExtraComment(data.hirechart?.tollc);

      // eslint-disable-next-line
      setEarlyCheckbox(data.hirechart?.early === '1' ? true : false);
      setEarlyMorningCheckbox(
        // eslint-disable-next-line
        data.hirechart?.early_morning === '1' ? true : false
      );
      // eslint-disable-next-line
      setLateCheckbox(data.hirechart?.late === '1' ? true : false);
      // eslint-disable-next-line
      setNiteCheckbox(data.hirechart?.onite === '1' ? true : false);
    }
  }, [data]);

  useEffect(() => {
    if (bmode === '2') {
      const rateVal = ratesData?.find(
        (r: any) =>
          r.cid === data.hirechart.company && r.vid === data.hirechart.vtype
      );
      if (rateVal) {
        setKms(rateVal.initial);
        setRate(rateVal.xkm);
        setWaitChgs(rateVal.xhr);
        if (rateVal?.mintype === '1' || rateVal?.mintype === '2') {
          setMinType(rateVal?.mintype);
        }
      }
    }
    // eslint-disable-next-line
  }, [bmode]);

  useEffect(() => {
    setExtrasData(extraData);

    if (ptollsData && extraData) {
      const extraCheckboxArr: any = [];
      // eslint-disable-next-line
      ptollsData = ptollsData?.filter(p => Number(p.hid) === Number(id));
      extrasData?.map((ex: any) => {
        if (ptollsData?.find(p => Number(p.eid) === Number(ex.id))) {
          extraCheckboxArr.push(true);
        } else {
          extraCheckboxArr.push(false);
        }

        return null;
      });
      setExtraCheckbox(extraCheckboxArr);
    }
  }, [extraData, ptollsData]);

  const { mutate: updateBillByHidMutate } = useUpdateBillByHid();
  const { mutate: updateHirechartMutate } = useUpdateHireChart();
  const { mutate: useDeletePtollByHidMutate } = useDeletePtollByHid();
  const { mutate: useAddPtollMutate } = useAddPtoll();

  if (isError) {
    return <div>Error fetching Bill</div>;
  }

  const updateBillDetails = () => {
    updateBillByHidMutate(
      {
        hid: id,
        mode: bmode,
        mintype: minType,
        kms,
        xkms: extraKms,
        rate,
        tot1: Number(extraKms) * Number(rate),
        xhrs: extraHrs,
        wchr: waitChgs,
        tot2: Number(extraHrs) * Number(waitChgs),
        early,
        early_morning: earlyMorning,
        late,
        onite: overnight,
        toll: extrasToll,
        tollc: extraComment,
        tid: transfer,
        tot3:
          Number(extraKms) * Number(rate) +
          Number(extraHrs) * Number(waitChgs) +
          Number(early) +
          Number(earlyMorning) +
          Number(late) +
          Number(overnight) +
          0 +
          Number(kms),
      },
      {}
    );

    updateHirechartMutate(
      {
        id,
        early: earlyCheckbox === true ? 1 : 0,
        early_morning: earlyMorningCheckbox === true ? 1 : 0,
        late: lateCheckbox === true ? 1 : 0,
        onite: niteCheckbox === true ? 1 : 0,
        toll: extrasToll,
        tollc: extraComment,
        bill: 1,
        particulars: particular,
        remark: premark,
      },
      {}
    );

    // eslint-disable-next-line
    useDeletePtollByHidMutate(
      {
        hid: id,
      },
      {
        onSuccess: () => {
          const addPtollArr: any = [];
          extraData?.map((ex: any, index: number) => {
            if (extraCheckbox[index]) {
              addPtollArr.push({
                hid: id,
                eid: ex.id,
                rate: ex.rate,
              });
            }
            return null;
          });
          // eslint-disable-next-line
          useAddPtollMutate(addPtollArr, {
            onSuccess: () => {
              enqueueSnackbar('Content Updated', { variant: 'info' });
            },
          });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  // const handlePrint = () => {
  //   if (document) {
  //     const printContent: string | undefined =
  //       document.getElementById('printData')?.innerHTML;
  //     if (printContent) {
  //       const originalContent: string = document.body.innerHTML;
  //       document.body.innerHTML = printContent;
  //       window.focus();
  //       window.print();
  //       document.body.innerHTML = originalContent;
  //     }
  //   }
  // };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Pending Bill Details"
          // primaryAction={
          //   // <Button variant="contained" color="primary" onClick={handlePrint}>
          //   <Button variant="contained" color="primary">
          //     Print
          //   </Button>
          // }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ p: 2 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div id="printData">
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box>
                        <Select
                          labelId="bmode"
                          id="bmode"
                          value={bmode}
                          onChange={e => setBmode(e.target.value)}
                          label="Billing Mode"
                        >
                          <MenuItem value="1">Tranfers</MenuItem>
                          <MenuItem value="2">Fixed Manual</MenuItem>
                          <MenuItem value="3">Manual</MenuItem>
                          <MenuItem value="4">General Transfers</MenuItem>
                          <MenuItem value="5">General Disposals</MenuItem>
                        </Select>
                      </Box>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Date: {formatDate(date)}</p>
                        </Box>
                        <Box>
                          <p>Company: {company}</p>
                        </Box>
                        <Box>
                          <p>AC: {ac}</p>
                        </Box>
                        <Box>
                          <p>Rep Veh: {repveh}</p>
                        </Box>
                      </Box>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Veh Type: {vehType}</p>
                        </Box>
                        <Box>
                          <p>Veh No: {vehNo}</p>
                        </Box>
                        <Box>
                          <p>Own code: {owncode}</p>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box>
                        <Grid md={8}>
                          <TextField
                            id="particular"
                            value={particular}
                            onChange={e => setParticular(e.target.value)}
                            label="Particular"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>
                        <Grid marginTop={4} md={8}>
                          <TextField
                            id="premark"
                            value={premark}
                            onChange={e => setPremark(e.target.value)}
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>
                        {(bmode === '1' || bmode === '4') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <Typography>Transfer</Typography>
                              <Select
                                labelId="transfer"
                                id="transfer"
                                value={transfer}
                                variant="outlined"
                                onChange={e => setTransfer(e.target.value)}
                                label="Transfer"
                              >
                                (
                                {transferData?.map((t: any) => (
                                  <MenuItem key={t.id} value={t.id}>
                                    {t.type}
                                  </MenuItem>
                                ))}
                                )
                              </Select>
                            </Box>
                          </Box>
                        )}

                        {(bmode === '3' || bmode === '2' || bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <Select
                                labelId="mintype"
                                id="minType"
                                value={minType}
                                onChange={e => setMinType(e.target.value)}
                                label="Min Type"
                              >
                                <MenuItem value="1">8 HRs / 100 KMs</MenuItem>
                                <MenuItem value="2">8 HRs / 80 KMs</MenuItem>
                              </Select>
                            </Box>
                            <Box paddingLeft={4} marginLeft={4}>
                              <TextField
                                id="kms"
                                value={kms}
                                onChange={e => setKms(e.target.value)}
                                label="Kms"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}

                        {(bmode === '3' || bmode === '2' || bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <TextField
                                id="extraKms"
                                value={extraKms}
                                onChange={e => setExtraKms(e.target.value)}
                                label="Extra Kms"
                                variant="outlined"
                              />
                            </Box>
                            <Box>
                              <TextField
                                id="rate"
                                value={rate}
                                onChange={e => setRate(e.target.value)}
                                label="Rate"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}
                        {(bmode === '3' || bmode === '2' || bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <TextField
                                id="totalExtraKms"
                                value={totalExtraKms}
                                onChange={e => setTotalExtraKms(e.target.value)}
                                label="Total Extra Kms"
                                variant="outlined"
                              />
                            </Box>
                            <Box>
                              <TextField
                                id="extraHrs"
                                value={extraHrs}
                                onChange={e => setExtraHrs(e.target.value)}
                                label="Extra Hours"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}

                        {(bmode === '3' || bmode === '2' || bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <TextField
                                id="waitChgs"
                                value={waitChgs}
                                onChange={e => setWaitChgs(e.target.value)}
                                label="Waiting Charges"
                                variant="outlined"
                              />
                            </Box>
                            <Box>
                              <TextField
                                id="totalWaiting"
                                value={totalWaiting}
                                onChange={e => setTotalWaiting(e.target.value)}
                                label="Total Waiting"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}

                        {bmode === '3' && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <TextField
                                id="early"
                                value={early}
                                onChange={e => setEarly(e.target.value)}
                                label="Early"
                                variant="outlined"
                              />
                            </Box>
                            <Box>
                              <TextField
                                id="early"
                                value={earlyMorning}
                                onChange={e => setEarlyMorning(e.target.value)}
                                label="Early Morning"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}
                        {bmode === '3' && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <TextField
                                id="late"
                                value={late}
                                onChange={e => setLate(e.target.value)}
                                label="Late"
                                variant="outlined"
                              />
                            </Box>
                            <Box>
                              <TextField
                                id="overnight"
                                value={overnight}
                                onChange={e => setOvernight(e.target.value)}
                                label="Overnight"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                        )}

                        {(bmode === '1' ||
                          bmode === '2' ||
                          bmode === '3' ||
                          bmode === '4' ||
                          bmode === '5') && (
                          <FormGroup row>
                            <FormControlLabel
                              key="earlyCheckbox"
                              control={
                                <Checkbox
                                  checked={earlyCheckbox}
                                  onChange={e =>
                                    setEarlyCheckbox(e.target.checked)
                                  }
                                />
                              }
                              label="Early"
                            />
                            <FormControlLabel
                              key="earlyMorningCheckbox"
                              control={
                                <Checkbox
                                  checked={earlyMorningCheckbox}
                                  onChange={e =>
                                    setEarlyMorningCheckbox(e.target.checked)
                                  }
                                />
                              }
                              label="Early Morning"
                            />
                            <FormControlLabel
                              key="late"
                              control={
                                <Checkbox
                                  checked={lateCheckbox}
                                  onChange={e =>
                                    setLateCheckbox(e.target.checked)
                                  }
                                />
                              }
                              label="Late"
                            />
                            <FormControlLabel
                              key="nite"
                              control={
                                <Checkbox
                                  checked={niteCheckbox}
                                  onChange={e =>
                                    setNiteCheckbox(e.target.checked)
                                  }
                                />
                              }
                              label="O.Nite"
                            />
                          </FormGroup>
                        )}

                        {(bmode === '1' ||
                          bmode === '2' ||
                          bmode === '3' ||
                          bmode === '4' ||
                          bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            {/* <FormGroup row> */}
                            <p>Extras</p>
                            {extrasData?.map((e: any, i: any) => (
                              <FormControlLabel
                                key={e.id}
                                control={
                                  <Checkbox
                                    id={e.id}
                                    checked={extraCheckbox[i]}
                                    onChange={ev => {
                                      const updatedExtraCheckbox: any = [];
                                      if (extraCheckbox) {
                                        extraCheckbox?.map(
                                          (ec: any, index: number) => {
                                            if (index === i) {
                                              updatedExtraCheckbox.push(
                                                ev.target.checked
                                              );
                                            } else {
                                              updatedExtraCheckbox.push(ec);
                                            }
                                            return null;
                                          }
                                        );
                                        setExtraCheckbox(updatedExtraCheckbox);
                                      }
                                    }}
                                  />
                                }
                                label={e.code}
                              />
                            ))}
                            {/* </FormGroup> */}
                            <TextField
                              id="extrasToll"
                              value={extrasToll}
                              onChange={e => setExtrasToll(e.target.value)}
                              label="Extras Toll"
                              variant="outlined"
                            />
                          </Box>
                        )}

                        {(bmode === '1' ||
                          bmode === '2' ||
                          bmode === '3' ||
                          bmode === '4' ||
                          bmode === '5') && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <TextField
                              id="extraComment"
                              value={extraComment}
                              onChange={e => setExtraComment(e.target.value)}
                              label="Extras Comment"
                              variant="outlined"
                            />
                          </Box>
                        )}

                        <Box paddingTop={4} marginTop={4}>
                          <Button
                            variant="contained"
                            onClick={updateBillDetails}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BillRegisterEdit;
