import { useFormContext } from 'react-hook-form';

import { Card, FormControl, Grid, TextField, Typography } from '@mui/material';

function NewContracts() {
  const { register } = useFormContext();

  // const [formData, setFormData] = useState({
  //   date: '',
  //   company: '',
  // });

  // const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleSelectChange = (e: SelectChangeEvent<string>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const { mutate } = useAddContracts();

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   mutate(
  //     {
  //       data: 'hello add contaract',
  //     },
  //     {
  //       onSuccess: data => {},
  //       onError: error => {},
  //     }
  //   );
  // };

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          {/* <form onSubmit={handleSubmit}> */}
          <Typography variant="h5">New Contract</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  id="vtype"
                  label="Vehicle"
                  {...register('vtype')}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  id="particular"
                  label="Particulars"
                  {...register('particular')}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  id="kms"
                  label="KMs/No of nights"
                  {...register('kms')}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  id="rate"
                  label="Rate"
                  {...register('rate')}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  id="amount"
                  label="Amount"
                  {...register('amount')}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* </form> */}
        </Card>
      </div>
    </div>
  );
}

export default NewContracts;
