import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import {
  IReadVehicleType,
  IVehicleType,
} from '../../types/controlpanel/vehicletype';

// READ ALL
const fetchVehicleTypes = async (): Promise<IVehicleType[]> => {
  const { data } = await apiClient.get(`/vehicle-type`);
  return data;
};

export const useReadVehicleTypes = () =>
  useQuery<IVehicleType[], Error>({
    queryKey: ['vehicletypes', 'list'],
    queryFn: fetchVehicleTypes,
  });

// READ ONE
const fetchVehicleType = async (
  params: IReadVehicleType
): Promise<IVehicleType> => {
  const { data } = await apiClient.get(`/vehicle-type/${params.id}`);
  return data;
};

export const useReadVehicleType = (params: IReadVehicleType) =>
  useQuery<IVehicleType, Error>({
    queryKey: ['vehicletypes', 'details', params.id],
    queryFn: () => fetchVehicleType(params),
  });

// useMutation
export const useAddVehicleType = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-vehicletypes'],
    mutationFn: (data: any) => apiClient.post('/vehicle-type', data),
  });
  return {
    mutate,
  };
};

export const useEditVehicleType = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-vehicletypes'],
    mutationFn: (data: any) => apiClient.put(`/vehicle-type/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteVehicleType = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-vehicletypes'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/vehicle-type/${id}`),
  });
  return { mutate };
};
