import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IReadUsers, IUsers } from '../../types/controlpanel/users';

// READ ALL
const fetchUsers = async (): Promise<IUsers[]> => {
  const { data } = await apiClient.get(`/users`);
  return data;
};

export const useReadUsers = () =>
  useQuery<IUsers[], Error>({
    queryKey: ['users', 'list'],
    queryFn: fetchUsers,
  });

// READ ONE
const fetchUser = async (params: IReadUsers): Promise<IUsers> => {
  const { data } = await apiClient.get(`/users/${params.id}`);
  return data;
};

export const useReadUser = (params: IReadUsers) =>
  useQuery<IUsers, Error>({
    queryKey: ['users', 'details', params.id],
    queryFn: () => fetchUser(params),
  });

// useMutation
export const useAddUsers = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-users'],
    mutationFn: (data: any) => apiClient.post('/users', data),
  });
  return {
    mutate,
  };
};

export const useEditUsers = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-users'],
    mutationFn: (data: any) => apiClient.put(`/users/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteUsers = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-users'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/users/${id}`),
  });

  return { mutate };
};
