import { Box, Drawer } from '@mui/material';

interface ContentComponentProps {
  data?: any;
}

interface DetailDialogProps {
  open: boolean;
  handleCloseDrawer: () => void;
  ContentComponent: React.ComponentType<ContentComponentProps>;
  data?: any;
}

const drawerWidth = 400;

const DetailDialog = ({
  open,
  handleCloseDrawer,
  ContentComponent,
  data,
}: DetailDialogProps) => (
  <Box sx={{ width: drawerWidth }}>
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: drawerWidth,
        },
      }}
    >
      <ContentComponent data={data} />
    </Drawer>
  </Box>
);

export default DetailDialog;
