import { useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

export const useGetTariff = ({ companyName }: { companyName: string }) => {
  const { data, refetch } = useQuery({
    queryKey: ['get-tariff'],
    queryFn: () =>
      apiClient.post('/subagent/tariff', {
        cid: companyName,
      }),
    enabled: !!companyName,
  });
  return {
    data: data?.data ?? [],
    refetch,
  };
};
