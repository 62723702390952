import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';

import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useReadExtras } from '../../hooks/extra';
import { useUpdateHireChart } from '../../hooks/hirechart';
import { useReadProformaMulti, useUpdateProforma } from '../../hooks/proforma';

const EditProforma = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  const [date, setDate] = useState('');
  const [company, setCompany] = useState('');
  const [client, setClient] = useState('');
  const [subagent, setSubagent] = useState('');
  const [ac, setAc] = useState('');
  const [vehNo, setVehNo] = useState('');
  const [vehType, setVehType] = useState('');

  const [particulars, setParticulars] = useState('');
  const [minType, setMinType] = useState('1');
  const [kms, setKms] = useState('');
  const [extraKms, setExtraKms] = useState('');
  const [totalExtraKms, setTotalExtraKms] = useState('');
  const [extraHrs, setExtraHrs] = useState('');
  const [totalWaiting, setTotalWaiting] = useState('');

  const [rate, setRate] = useState('');
  const [waitChgs, setWaitChgs] = useState('');

  const [early, setEarly] = useState('');
  const [earlyMorning, setEarlyMorning] = useState('');
  const [late, setLate] = useState('');
  const [overnight, setOvernight] = useState('');

  const [extrasToll, setExtrasToll] = useState('');

  const [extraCheckbox, setExtraCheckbox] = useState<any>([]);
  const [extrasData, setExtrasData] = useState<any>([]);

  const [additionalAmount1, setAdditionalAmount1] = useState('');
  const [additionalAmount2, setAdditionalAmount2] = useState('');
  const [additionalAmount3, setAdditionalAmount3] = useState('');
  const [additionalAmount4, setAdditionalAmount4] = useState('');

  const [additionalChargs1, setAdditionalChargs1] = useState('');
  const [additionalChargs2, setAdditionalChargs2] = useState('');
  const [additionalChargs3, setAdditionalChargs3] = useState('');
  const [additionalChargs4, setAdditionalChargs4] = useState('');

  const { data: extraData } = useReadExtras();

  const { data, isLoading, isError } = useReadProformaMulti({
    id,
  } as any);

  useEffect(() => {
    document.title = 'Proforma - nunes';
    if (data?.pDetails) {
      setParticulars(data?.hDetails?.particulars);
      setMinType(data?.pDetails?.mintype);
      setKms(data?.pDetails?.kms);
      setExtraKms(data?.pDetails?.xkms);
      setTotalExtraKms(data?.pDetails?.tot1);
      setTotalWaiting(data?.pDetails?.tot2);
      setRate(data?.pDetails?.rate);
      setExtraHrs(data?.pDetails?.xhrs);
      setWaitChgs(data?.pDetails?.wchr);
      setEarly(data?.pDetails?.early);
      setEarlyMorning(data?.pDetails?.early_morning);
      setLate(data?.pDetails?.late);
      setOvernight(data?.pDetails?.onite);

      setDate(data?.hDetails?.date);
      setCompany(data?.hDetails?.company);
      setAc(data?.hDetails?.ac);
      setSubagent(data?.hDetails?.subagent);
      setClient(data?.hDetails?.client);
      setVehNo(data?.hDetails?.vno);
      setVehType(data?.hDetails?.vtype);

      setAdditionalAmount1(data?.hDetails?.additional_amount1);
      setAdditionalAmount2(data?.hDetails?.additional_amount2);
      setAdditionalAmount3(data?.hDetails?.additional_amount3);
      setAdditionalAmount4(data?.hDetails?.additional_amount4);

      setAdditionalChargs1(data?.hDetails?.additional_chargs1);
      setAdditionalChargs2(data?.hDetails?.additional_chargs2);
      setAdditionalChargs3(data?.hDetails?.additional_chargs3);
      setAdditionalChargs4(data?.hDetails?.additional_chargs4);
    }
  }, [data]);

  useEffect(() => {
    setExtrasData(extraData);
  }, [extraData]);

  const { mutate: updateProformaMutate } = useUpdateProforma();
  const { mutate: updateHirechartMutate } = useUpdateHireChart();

  if (isError) {
    return <div>Error fetching Proforma</div>;
  }

  const updateContractDetails = () => {
    updateProformaMutate(
      {
        id: data?.pDetails?.id,
        mintype: minType,
        kms,
        xkms: extraKms,
        rate,
        tot1: Number(extraKms) * Number(rate),
        xhrs: extraHrs,
        wchr: waitChgs,
        tot2: Number(extraHrs) * Number(waitChgs),
        early,
        early_morning: earlyMorning,
        late,
        onite: overnight,
        toll: '',
        ptoll: '',
        tot3:
          Number(extraKms) * Number(rate) +
          Number(extraHrs) * Number(waitChgs) +
          Number(early) +
          Number(earlyMorning) +
          Number(late) +
          Number(overnight) +
          0 +
          Number(kms),
      },
      {}
    );

    updateHirechartMutate(
      {
        id: data?.pDetails?.hid,
        additional_amount1: additionalAmount1,
        additional_chargs1: additionalChargs1,
        additional_amount2: additionalAmount2,
        additional_chargs2: additionalChargs2,
        additional_amount3: additionalAmount3,
        additional_chargs3: additionalChargs3,
        additional_amount4: additionalAmount4,
        additional_chargs4: additionalChargs4,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  // const handlePrint = () => {
  //   if (document) {
  //     const printContent: string | undefined =
  //       document.getElementById('printData')?.innerHTML;
  //     if (printContent) {
  //       const originalContent: string = document.body.innerHTML;
  //       document.body.innerHTML = printContent;
  //       window.focus();
  //       window.print();
  //       document.body.innerHTML = originalContent;
  //     }
  //   }
  // };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Proforma Details"
          // primaryAction={
          //   // <Button variant="contained" color="primary" onClick={handlePrint}>
          //   <Button variant="contained" color="primary">
          //     Print
          //   </Button>
          // }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ p: 2 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div id="printData">
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Date: {formatDate(date)}</p>
                        </Box>
                        <Box>
                          <p>Company: {company}</p>
                        </Box>
                        <Box>
                          <p>Subagent: {subagent}</p>
                        </Box>
                      </Box>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Client: {client}</p>
                        </Box>
                        <Box>
                          <p>AC/NAC: {ac}</p>
                        </Box>
                        <Box>
                          <p>Veh Type: {vehType}</p>
                        </Box>
                        <Box>
                          <p>Veh No: {vehNo}</p>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box>
                        <Box>
                          <p>Particulars: {particulars}</p>
                        </Box>
                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <Box>
                            <Select
                              labelId="mintype"
                              id="minType"
                              value={minType}
                              onChange={e => setMinType(e.target.value)}
                              label="Min Type"
                            >
                              <MenuItem value="1">8 HRs / 100 KMs</MenuItem>
                              <MenuItem value="2">8 HRs / 80 KMs</MenuItem>
                            </Select>
                          </Box>
                          <Box paddingLeft={4} marginLeft={5}>
                            <TextField
                              id="kms"
                              value={kms}
                              onChange={e => setKms(e.target.value)}
                              label="Kms"
                              variant="outlined"
                            />
                          </Box>
                        </Box>

                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <Box>
                            <TextField
                              id="extraKms"
                              value={extraKms}
                              onChange={e => setExtraKms(e.target.value)}
                              label="Extra Kms"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="totalExtraKms"
                              value={totalExtraKms}
                              onChange={e => setTotalExtraKms(e.target.value)}
                              label="Total Extra Kms"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="extraHrs"
                              value={extraHrs}
                              onChange={e => setExtraHrs(e.target.value)}
                              label="Extra Hours"
                              variant="outlined"
                            />
                          </Box>
                        </Box>

                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <Box>
                            <TextField
                              id="totalWaiting"
                              value={totalWaiting}
                              onChange={e => setTotalWaiting(e.target.value)}
                              label="Total Waiting"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="rate"
                              value={rate}
                              onChange={e => setRate(e.target.value)}
                              label="Rate"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="waitChgs"
                              value={waitChgs}
                              onChange={e => setWaitChgs(e.target.value)}
                              label="Wait Charges"
                              variant="outlined"
                            />
                          </Box>
                        </Box>

                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <Box>
                            <TextField
                              id="early"
                              value={early}
                              onChange={e => setEarly(e.target.value)}
                              label="Early"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="early"
                              value={earlyMorning}
                              onChange={e => setEarlyMorning(e.target.value)}
                              label="Early Morning"
                              variant="outlined"
                            />
                          </Box>
                        </Box>
                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <Box>
                            <TextField
                              id="late"
                              value={late}
                              onChange={e => setLate(e.target.value)}
                              label="Late"
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <TextField
                              id="overnight"
                              value={overnight}
                              onChange={e => setOvernight(e.target.value)}
                              label="Overnight"
                              variant="outlined"
                            />
                          </Box>
                        </Box>
                        <Box my={4} gap={4} alignItems="center" display="flex">
                          {/* <FormGroup row> */}
                          <p>Extras</p>
                          {extrasData?.map((e: any, i: any) => (
                            <FormControlLabel
                              key={e?.id}
                              control={
                                <Checkbox
                                  value={extraCheckbox[i]}
                                  onChange={ev =>
                                    setExtraCheckbox(ev?.target?.checked)
                                  }
                                />
                              }
                              label={e?.code}
                            />
                          ))}
                          {/* </FormGroup> */}
                          <TextField
                            id="extrasToll"
                            value={extrasToll}
                            onChange={e => setExtrasToll(e.target.value)}
                            label="Extras Toll"
                            variant="outlined"
                          />
                        </Box>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 1"
                              value={additionalChargs1}
                              onChange={e =>
                                setAdditionalChargs1(e.target.value)
                              }
                              label="Additional Charges 1 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 1"
                              value={additionalAmount1}
                              onChange={e =>
                                setAdditionalAmount1(e.target.value)
                              }
                              label="Amount 1"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 2"
                              value={additionalChargs2}
                              onChange={e =>
                                setAdditionalChargs2(e.target.value)
                              }
                              label="Additional Charges 2 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 2"
                              value={additionalAmount2}
                              onChange={e =>
                                setAdditionalAmount2(e.target.value)
                              }
                              label="Amount 2"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 3"
                              value={additionalChargs3}
                              onChange={e =>
                                setAdditionalChargs3(e.target.value)
                              }
                              label="Additional Charges 3 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 3"
                              value={additionalAmount3}
                              onChange={e =>
                                setAdditionalAmount3(e.target.value)
                              }
                              label="Amount 3"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 4"
                              value={additionalChargs4}
                              onChange={e =>
                                setAdditionalChargs4(e.target.value)
                              }
                              label="Additional Charges 4 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 4"
                              value={additionalAmount4}
                              onChange={e =>
                                setAdditionalAmount4(e.target.value)
                              }
                              label="Amount 4"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Box paddingTop={2}>
                          <Button
                            variant="contained"
                            onClick={updateContractDetails}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditProforma;
