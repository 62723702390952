import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IGST, IReadGST } from '../../types/controlpanel/gst';

// READ ALL
const fetchGsts = async (): Promise<IGST[]> => {
  const { data } = await apiClient.get(`/gst`);
  return data;
};

export const useReadGsts = () =>
  useQuery<IGST[], Error>({
    queryKey: ['gst', 'list'],
    queryFn: fetchGsts,
  });

// READ ONE
const fetchGst = async (params: IReadGST): Promise<IGST> => {
  const { data } = await apiClient.get(`/gst/${params.id}`);
  return data;
};

export const useReadGst = (params: IReadGST) =>
  useQuery<IGST, Error>({
    queryKey: ['gst', 'details', params.id],
    queryFn: () => fetchGst(params),
  });

// useMutation
export const useAddGst = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-gst'],
    mutationFn: (data: any) => apiClient.post('/gst', data),
  });
  return {
    mutate,
  };
};

export const useEditGst = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-gst'],
    mutationFn: (data: any) => apiClient.put(`/gst/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteGst = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-gst'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/gst/${id}`),
  });
  return { mutate };
};
