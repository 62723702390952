import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IProforma, IReadProforma } from '../types/proforma';

// READ ALL
const fetchProformaRegs = async (): Promise<IProforma[]> => {
  const { data } = await apiClient.get(`/proformareg`);
  return data;
};

const fetchProformas = async (): Promise<IProforma[]> => {
  const { data } = await apiClient.get(`/proforma`);
  return data;
};

const fetchProformasByNo = async (): Promise<IProforma[]> => {
  const { data } = await apiClient.get(`/proforma`);
  return data;
};

export const useReadProformaRegs = () =>
  useQuery<IProforma[], Error>({
    queryKey: ['proformaRegs', 'list'],
    queryFn: () => fetchProformaRegs(),
  });

export const useReadProformas = () =>
  useQuery<IProforma[], Error>({
    queryKey: ['proformas', 'list'],
    queryFn: () => fetchProformas(),
  });

export const useReadProformasByNo = () =>
  useQuery<IProforma[], Error>({
    queryKey: ['proformas', 'listByNo'],
    queryFn: () => fetchProformasByNo(),
  });

// READ ONE
export const fetchProformaMulti = async (params: IReadProforma) => {
  const { data: proformaData } = await apiClient.get(`/proforma/${params.id}`);
  const { data: hirechartData } = await apiClient.get(
    `/hirechart/${proformaData?.hid}`
  );
  return {
    pDetails: proformaData,
    hDetails: hirechartData,
  };
};

// READ ONE
const fetchProforma = async (params: IReadProforma): Promise<IProforma> => {
  const { data } = await apiClient.get(`/proforma/${params.id}`);
  return data;
};

export const useReadProforma = (params: IReadProforma) =>
  useQuery<any, Error>({
    queryKey: ['proformas', 'details', params.id],
    queryFn: () => fetchProforma(params),
  });

export const useReadProformaMulti = (params: IReadProforma) =>
  useQuery<any, Error>({
    queryKey: ['proformas', 'details-multi', params.id],
    queryFn: () => fetchProformaMulti(params),
  });

// useMutation
export const useAddProforma = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-proformas'],
    mutationFn: (data: any) => apiClient.post('/proforma', data),
  });
  return { mutate };
};

export const useEditProforma = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-proforma'],
    mutationFn: (data: any) => apiClient.put('/proforma', data),
  });
  return { mutate };
};

export const useGetHirechartByProforma = () => {
  const { mutate } = useMutation({
    mutationKey: ['hirechart-proforma'],
    mutationFn: (data: any) =>
      apiClient.post('/hirechart/proforma', { proformano: data.proformano }),
  });
  return { mutate };
};

export const useUpdateProforma = () => {
  const { mutate } = useMutation({
    mutationKey: ['update-proforma'],
    mutationFn: (data: any) => apiClient.put(`/proforma/${data.id}`, data),
  });
  return { mutate };
};

export const useDeleteProforma = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-proformas'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/proforma/${id}`),
  });
  return { mutate };
};

export const useDeleteProformaReg = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-proformaRegs'],
    mutationFn: ({ id }: { id: number }) =>
      apiClient.delete(`/proformareg/${id}`),
  });
  return { mutate };
};
