import React from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import BillRegister from '@pages/Bill/BillRegister';
import BillRegisterDetail from '@pages/Bill/BillRegisterDetail';
import BillRegisterEdit from '@pages/Bill/BillRegisterEdit';
import DirectBill from '@pages/Bill/DirectBill';
import DirectBillDetail from '@pages/Bill/DirectBillDetail';
import DirectBillEdit from '@pages/Bill/DirectBillEdit';
import DirectBillView from '@pages/Bill/DirectBillView';
// import NewBill from '@pages/Bill/NewBill';
// import NewBillEdit from '@pages/Bill/NewBillEdit';
// import NewBillEditHire from '@pages/Bill/NewBillEditHire';
import PendingBillEdit from '@pages/Bill/PendingBillEdit';
import PendingBills from '@pages/Bill/PendingBills';
// import PrintBill from '@pages/Bill/PrintBill';
// import PrintBillRegister from '@pages/Bill/PrintBillRegister';
import Contact from '@pages/Contact';
import ContractDetails from '@pages/Contracts/ContractDetails';
import ContractView from '@pages/Contracts/ContractView';
import Contracts from '@pages/Contracts/index';
import ControlPanel from '@pages/ControlPanel';
import BillDates from '@pages/ControlPanel/BillDates';
import Companies from '@pages/ControlPanel/Companies';
import CompanyTariff from '@pages/ControlPanel/Companies/CompanyTariff';
// import Companies from '@pages/ControlPanel/Companies';
import Driver from '@pages/ControlPanel/Drivers';
import ExtraCharges from '@pages/ControlPanel/ExtraCharges';
import GeneralTariff from '@pages/ControlPanel/GeneralTariff';
import GstData from '@pages/ControlPanel/GST';
import Hotel from '@pages/ControlPanel/Hotel';
import NewCompanies from '@pages/ControlPanel/NewCompanies';
import OwnerCodes from '@pages/ControlPanel/OwnerCode';
import Sector from '@pages/ControlPanel/Sector';
import StaffList from '@pages/ControlPanel/Staff';
import SubAgent from '@pages/ControlPanel/SubAgent';
import SubAgentTariff from '@pages/ControlPanel/SubAgent/SubAgentTariff';
import Transfer from '@pages/ControlPanel/Transfer';
import Users from '@pages/ControlPanel/Users';
import VehicleNumber from '@pages/ControlPanel/VehicleNumber';
import VehicleType from '@pages/ControlPanel/VehicleType';
import HireChart from '@pages/HireChart';
import EditHireChart from '@pages/HireChart/EditHireChart';
import PdcHire from '@pages/HireChart/PdcHire';
import Home from '@pages/Home';
import LoginPage from '@pages/Login';
import Profile from '@pages/Profile';
import Proforma from '@pages/Proforma';
import EditProforma from '@pages/Proforma/EditProforma';
import ProformaDetails from '@pages/Proforma/ProformaDetails';
// import DirectBill from '@components/Bill/DirectBill';
import BreakdownIncidence from '@pages/Reports/BreakdownIncidence';
import UnbilledHires from '@pages/Reports/UnbilledHires';
import VehicleDrivers from '@pages/Reports/VehicleDrivers';
// import VehicleNumbers from '@pages/Reports/VehicleNumbers';
import VehicleNumbersReport from '@pages/Reports/VehicleNumbers';
import VehicleSuppliers from '@pages/Reports/VehicleSuppliers';

import MiniDrawer from '@components/layouts/layout';
import PrivateRoute from '@components/PrivateRoute/PrivateRoute';

// import NotFound from './pages/NotFound';

const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MiniDrawer />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path="contacts" element={<Contact />} />
        <Route path="hirechart" element={<HireChart />} />
        <Route path="/editHire" element={<EditHireChart />} />
        <Route path="/pdcHire" element={<PdcHire />} />
        <Route path="proforma" element={<Proforma />} />
        <Route path="/newBill/:id/edit" element={<DirectBillEdit />} />
        <Route path="/newBill/:id" element={<DirectBillDetail />} />
        <Route path="/bill/view" element={<DirectBillView />} />
        {/* <Route path="/newBill" element={<NewBill />} />
        <Route path="/newBill/edit" element={<NewBillEdit />} />
        <Route path="/newBill/edit/:id/hire" element={<NewBillEditHire />} /> */}
        <Route path="/billRegister/:id/edit" element={<BillRegisterEdit />} />
        <Route path="/billRegister/:id" element={<BillRegisterDetail />} />
        <Route path="/proformaDetails/:id" element={<ProformaDetails />} />
        <Route path="/edit-proforma/:id" element={<EditProforma />} />
        <Route path="contracts" element={<Contracts />} />
        <Route path="/contractDetails/:id" element={<ContractDetails />} />
        <Route path="/contracts/view" element={<ContractView />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pendingBills" element={<PendingBills />} />
        <Route path="/pendingBills/:id" element={<PendingBillEdit />} />
        <Route path="/newBill" element={<DirectBill />} />
        <Route path="/billRegister" element={<BillRegister />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/unbilledHires" element={<UnbilledHires />} />
        <Route path="/vehicleSuppliers" element={<VehicleSuppliers />} />
        {/* <Route path="/vehicleNumbers" element={<VehicleNumbers />} /> */}
        <Route
          path="/VehicleNumbersReport"
          element={<VehicleNumbersReport />}
        />
        <Route path="/VehicleDriversReport" element={<VehicleDrivers />} />
        <Route path="/BreakdownIncidence" element={<BreakdownIncidence />} />
        <Route path="/controlpanel" element={<ControlPanel />} />
        <Route path="/vehicleType" element={<VehicleType />} />
        <Route path="/vehicleNumber" element={<VehicleNumber />} />
        <Route path="/ownerCode" element={<OwnerCodes />} />
        <Route path="/driver" element={<Driver />} />
        <Route path="/staff" element={<StaffList />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/subAgent" element={<SubAgent />} />
        <Route path="/sector" element={<Sector />} />
        <Route path="/companyTariff" element={<CompanyTariff />} />
        <Route path="/users" element={<Users />} />
        <Route path="/hotel" element={<Hotel />} />
        <Route path="/billDates" element={<BillDates />} />
        <Route path="/subAgent" element={<SubAgent />} />
        <Route path="/sector" element={<Sector />} />
        <Route path="/companyTariff/:id" element={<CompanyTariff />} />
        <Route path="/users" element={<Users />} />
        <Route path="/hotel" element={<Hotel />} />
        <Route path="/billDates" element={<BillDates />} />
        <Route path="/newCompanies" element={<NewCompanies />} />
        <Route path="/Companies" element={<Companies />} />
        <Route path="/extra" element={<ExtraCharges />} />
        <Route path="/gst" element={<GstData />} />
        <Route path="/subAgent" element={<SubAgent />} />
        <Route path="/companyTariff" element={<CompanyTariff />} />
        <Route path="/generalTariff" element={<GeneralTariff />} />
        <Route path="/subAgentTariff/:id" element={<SubAgentTariff />} />
      </Route>

      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
);

export default AppRoutes;
