import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddHotel from '@components/ControlPanel/Hotel/AddHotel';
import EditHotel from '@components/ControlPanel/Hotel/EditHotel';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddHotel,
  useDeleteHotel,
  useEditHotel,
  useReadHotels,
} from '../../../hooks/controlpanel/hotels';
import { IHotel } from '../../../types/controlpanel/hotels';

const Hotels = () => {
  const { data: hotels, isLoading, refetch } = useReadHotels();

  const [localhotel, setLocalHotel] = useState<IHotel[]>([]);
  const [hotel, setHotel] = useState<IHotel | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteHotelMutate } = useDeleteHotel();
  const { mutate: addHotelMutate } = useAddHotel();
  const { mutate: editHotelMutate } = useEditHotel();

  useEffect(() => {
    if (hotels) {
      setLocalHotel(hotels);
    }
  }, [hotels]);

  const handleEdit = (rowData: any) => {
    setHotel(rowData);
    setDefaultvalue({
      hotel_name: rowData?.hotel_name,
      hotel_initial: rowData?.hotel_initial,
      sector: rowData?.sectorid,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setHotel(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (hotel && action && action?.action) {
      editHotelMutate(
        { ...action?.formData, id: hotel.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (hotel) {
      deleteHotelMutate(
        { id: +hotel.id },
        {
          onSuccess: () => {
            setLocalHotel(prevHotel =>
              prevHotel.filter(c => c.id !== hotel.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addHotelMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IHotel>[] = [
    {
      title: 'Hotel Name',
      field: 'hotel_name',
    },
    {
      title: 'Hotel Initial',
      field: 'hotel_initial',
    },
    {
      title: 'Sector Initial',
      field: 'sectorid',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Hotel Details"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Hotel
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localhotel || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: true,
                search: true,
                searchFieldVariant: 'outlined',
                pageSize: 10,
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddHotel}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditHotel}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Hotels;
