import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

const fetchPtolls = async () => {
  const { data } = await apiClient.get(`/ptoll`);
  return data;
};

export const useReadPtolls = () =>
  useQuery<any[], Error>({
    queryKey: ['ptoll', 'list'],
    queryFn: fetchPtolls,
  });

export const useAddPtoll = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-ptoll'],
    mutationFn: (dataArr: any) =>
      apiClient.post('/ptoll/many', { data: dataArr }),
  });
  return { mutate };
};

export const useDeletePtollByHid = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-ptollByHid'],
    mutationFn: (data: any) => apiClient.post('/ptoll/hid/delete', data),
  });
  return { mutate };
};
