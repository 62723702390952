import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';
import EditProfile from '@components/Profile/EditProfile';

import { useEditProfile, useReadProfiles } from '../../hooks/profile';

function Profile() {
  const { data: profile, refetch } = useReadProfiles();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState({
    firstname: '',
    lastname: '',
    password: '',
  });
  const { mutate } = useEditProfile();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = 'Profile - nunes';
  }, []);

  const handleEdit = (profileData: any) => {
    setDefaultvalue({
      firstname: profileData.firstname,
      lastname: profileData.lastname,
      password: '',
    });
    setEditDialogOpen(true);
  };

  const handleCloseDialog = (action: any) => {
    if (action && action?.action) {
      if (action?.formData?.password === '') {
        delete action.formData.password;
      }
      mutate(action?.formData, {
        onSuccess: () => {
          setEditDialogOpen(false);
          refetch();
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', {
            variant: 'error',
          });
        },
      });
    }
    setEditDialogOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid
        sx={{ paddingLeft: '10px', marginTop: '1rem', marginBottom: '10px' }}
      >
        <PageTitle
          text="Profile"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEdit(profile)}
            >
              <AddIcon sx={{ mr: 1 }} />
              Edit Profile
            </Button>
          }
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="110">First Name:</TableCell>
              <TableCell>{profile?.firstname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name:</TableCell>
              <TableCell>{profile?.lastname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Username:</TableCell>
              <TableCell>{profile?.useremail}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Role:</TableCell>
              <TableCell>
                {profile?.userlevel === '0' && 'User'}
                {profile?.userlevel === '1' && 'Power User'}
                {profile?.userlevel === '2' && 'Administrator'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseDialog}
        billMade={false}
        ContentComponent={EditProfile}
        defaultvalue={defaultvalue}
      />
    </Grid>
  );
}

export default Profile;
