import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { Grid } from '@mui/material';

import AddNewHire from '@components/HireCharts/AddNewHire';
import PageTitle from '@components/PageTitle';

import { useAddHireChart } from '../../hooks/hirechart';

const HireChart = () => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addHirechartMutate } = useAddHireChart();

  const handleFormSubmit = (formData: any) => {
    addHirechartMutate(formData, {
      onSuccess: () => {
        enqueueSnackbar('Hire added successfully', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Failed to add hire', { variant: 'error' });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Grid
        container
        direction="column"
        spacing={1}
        wrap="nowrap"
        sx={{
          height: '100%',
          padding: { xs: '0.5rem', sm: '1rem' },
        }}
      >
        <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
          <PageTitle text="New Hire" />
        </Grid>

        <Grid item xs={12}>
          <AddNewHire onSubmit={methods.handleSubmit(handleFormSubmit)} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default HireChart;
