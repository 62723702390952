import { useMutation, useQuery } from '@tanstack/react-query';

import apiClient from '@constants/axios';

import { IHotel, IReadHotel } from '../../types/controlpanel/hotels';

// READ ALL
const fetchHotels = async (): Promise<IHotel[]> => {
  const { data } = await apiClient.get(`/hotel`);
  return data;
};

export const useReadHotels = () =>
  useQuery<IHotel[], Error>({
    queryKey: ['hotel', 'list'],
    queryFn: fetchHotels,
  });

// READ ONE
const fetchHotel = async (params: IReadHotel): Promise<IHotel> => {
  const { data } = await apiClient.get(`/hotel/${params.id}`);
  return data;
};

export const useReadHotel = (params: IReadHotel) =>
  useQuery<IHotel, Error>({
    queryKey: ['hotel', 'details', params.id],
    queryFn: () => fetchHotel(params),
  });

// useMutation
export const useAddHotel = () => {
  const { mutate } = useMutation({
    mutationKey: ['add-hotel'],
    mutationFn: (data: any) => apiClient.post('/hotel', data),
  });
  return {
    mutate,
  };

  // const { data,refetch } = useQuery({
  //   queryKey: ['add-hotel'],
  //   queryFn: (payload: any) => apiClient.post('/hotel', payload),
  // });
  // return {
  //   data:data?.data,
  //   refetch
  // };
};

export const useEditHotel = () => {
  const { mutate } = useMutation({
    mutationKey: ['edit-hotel'],
    mutationFn: (data: any) => apiClient.put(`/hotel/${data?.id}`, data),
  });
  return { mutate };
};

export const useDeleteHotel = () => {
  const { mutate } = useMutation({
    mutationKey: ['delete-hotel'],
    mutationFn: ({ id }: { id: number }) => apiClient.delete(`/hotel/${id}`),
  });
  return { mutate };
};
