import { Controller, useFormContext } from 'react-hook-form';

import { Card, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { useReadGsts } from '../../../hooks/controlpanel/gst';

function EditNewCompanies() {
  const { control, register, getValues } = useFormContext();
  const { data: gsts } = useReadGsts();

  const values = getValues();

  return (
    <div className="content">
      <div className="content-inner">
        <Card style={{ marginTop: '20px', padding: '10px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            Edit Companies
          </Typography>
          <Grid container spacing={2} style={{ padding: '0px' }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                variant="outlined"
                label="Name"
                {...register('name')}
                fullWidth
                margin="normal"
                defaultValue={values.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cname"
                variant="outlined"
                label="Trade Name"
                {...register('cname')}
                fullWidth
                margin="normal"
                defaultValue={values.cname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="info"
                variant="outlined"
                label="More Info"
                {...register('info')}
                fullWidth
                margin="normal"
                defaultValue={values.info}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="gstno"
                variant="outlined"
                label="GST No"
                {...register('gstno')}
                fullWidth
                margin="normal"
                defaultValue={values.gstno}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="panno"
                variant="outlined"
                label="PAN No"
                {...register('panno')}
                fullWidth
                margin="normal"
                defaultValue={values.panno}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address"
                variant="outlined"
                label="Address"
                {...register('address')}
                fullWidth
                margin="normal"
                defaultValue={values.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="mintype"
                control={control}
                defaultValue={values.mintype}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="mintype"
                    variant="outlined"
                    label="8 HRs/100 KMs or 8 HRs/80 KMs"
                    fullWidth
                    margin="normal"
                    select
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="1">8 HRs/100 KMs</MenuItem>
                    <MenuItem value="2">8 HRs/80 KMs</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid container spacing={2} style={{ marginLeft: '0px' }}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="gst"
                  control={control}
                  defaultValue={values.gst}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="gst"
                      variant="outlined"
                      label="Tax"
                      fullWidth
                      margin="normal"
                      select
                    >
                      <MenuItem value="">Select</MenuItem>
                      {gsts?.map(gst => (
                        <MenuItem key={gst.tax} value={gst.tax}>
                          {gst?.tax}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="gstdate"
                  variant="outlined"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  {...register('gstdate')}
                  fullWidth
                  margin="normal"
                  defaultValue={values.gstdate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="gstdate1"
                  variant="outlined"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  {...register('gstdate1')}
                  fullWidth
                  margin="normal"
                  defaultValue={values.gstdate1}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginLeft: '0px' }}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="gst2"
                  control={control}
                  defaultValue={values.gst2}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="gst2"
                      variant="outlined"
                      label="New Tax"
                      fullWidth
                      margin="normal"
                      select
                    >
                      <MenuItem value="">Select</MenuItem>
                      {gsts?.map(gst => (
                        <MenuItem key={gst.tax} value={gst.tax}>
                          {gst?.tax}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="gstdate2"
                  variant="outlined"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  {...register('gstdate2')}
                  fullWidth
                  margin="normal"
                  defaultValue={values.gstdate2}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="gstdate3"
                  variant="outlined"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  {...register('gstdate3')}
                  fullWidth
                  margin="normal"
                  defaultValue={values.gstdate3}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="gsttype"
                control={control}
                defaultValue={values.gsttype || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="gsttype"
                    variant="outlined"
                    label="Tax Type"
                    fullWidth
                    margin="normal"
                    select
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="1">GST</MenuItem>
                    <MenuItem value="2">IGST</MenuItem>
                    <MenuItem value="3">Exempted</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="status"
                control={control}
                defaultValue={values.status || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="status"
                    variant="outlined"
                    label="Active/Inactive"
                    fullWidth
                    margin="normal"
                    select
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="2">InActive</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default EditNewCompanies;
